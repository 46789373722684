const breadcrumbs = {
  accessLevels: 'Access levels',
  accounts: 'Accounts',
  controllers: 'Controllers',
  credentials: 'Credentials',
  dealerInfo: 'Dealer info',
  doors: 'Doors',
  events: 'Events',
  firmwareManager: 'Firmware manager',
  floorPlans: 'Floor plans',
  globalSettings: 'Global settings',
  hardware: 'Hardware',
  holidays: 'Holidays',
  inputs: 'Inputs',
  interlocks: 'Interlocks',
  lockdowns: 'Lockdowns',
  notifications: 'Notifications',
  outputs: 'Outputs',
  reports: 'Reports',
  schedules: 'Schedules',
  settings: 'Settings',
  sites: 'Sites',
  users: 'Users',

  create: 'Create',
  edit: 'Edit',
};

export default breadcrumbs;
