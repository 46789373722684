import { ReactNode, useEffect, useState } from 'react';
import {
  Box,
  Avatar as MaterialUIAvatar,
  IconButton,
  SxProps,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import { toBase64 } from 'helpers/imgBase64';
import { grey } from '@mui/material/colors';
import { getUserorgImageURL } from 'api/api';
import useAlert from 'hooks/useAlert';
import { useTranslation } from 'react-i18next';
import { TEMPORAL_IMAGE_BYTE_LIMIT } from 'config/constants';

interface AvatarProps {
  children?: ReactNode;
  src?: string;
  url?: string;
  setSrc?: (s: string) => void;
  sx?: SxProps;
  containerSx?: SxProps;
  variant?: 'circular' | 'rounded' | 'square';
  editIcon?: boolean;
  deleteIcon?: boolean;
  onDeleteClick?: () => void;
  className?: string;
  disabled?: boolean;
}

const Avatar = ({
  children,
  src,
  url,
  setSrc,
  sx,
  containerSx,
  variant = 'circular',
  editIcon,
  deleteIcon,
  onDeleteClick,
  className,
  disabled,
}: AvatarProps) => {
  const { t } = useTranslation();
  const { errorAlert } = useAlert();
  const [image, setImage] = useState('');

  useEffect(() => {
    url && fetchUserImage();
  }, [url]);

  const fetchUserImage = async () => {
    try {
      const { user_org_img } = await getUserorgImageURL(String(url));
      setImage(
        user_org_img ? `data:image/png;base64,${user_org_img}` : user_org_img,
      );
    } catch (error) {
      console.error(error);
    }
  };

  const selectFile = async () => {
    const inp = document.createElement('input');
    inp.type = 'file';
    inp.accept = 'image/png, image/jpeg';
    inp.click();
    inp.addEventListener('change', async (e: any) => {
      try {
        const file = e.target.files[0];

        if (file.size > TEMPORAL_IMAGE_BYTE_LIMIT) {
          errorAlert(t('general.file_too_big'));
          return;
        }

        const contentBuffer = await toBase64(file);
        setSrc && setSrc(contentBuffer as string);
      } catch (error) {
        console.error(error);
      }
    });
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'left',
        ...containerSx,
      }}
    >
      <Box sx={{ position: 'relative' }}>
        <MaterialUIAvatar
          className={className || ''}
          sx={{
            fontSize: '2rem',
            width: '3em',
            height: '3em',
            ...sx,
          }}
          src={url && image ? image : src || ''}
          variant={variant}
        >
          {children}
        </MaterialUIAvatar>

        {editIcon && (
          <IconButton
            disableRipple
            disabled={disabled}
            onClick={selectFile}
            sx={{
              position: 'absolute',
              right: -8,
              top: 0,
              background: grey[200],
            }}
            size="small"
          >
            <EditIcon fontSize="small" />
          </IconButton>
        )}

        {deleteIcon && (
          <IconButton
            disableRipple
            disabled={disabled}
            onClick={onDeleteClick}
            sx={{
              position: 'absolute',
              right: -8,
              bottom: 0,
              background: grey[200],
            }}
            size="small"
          >
            <ClearIcon fontSize="small" />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};

export default Avatar;
