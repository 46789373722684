const general = {
  sites: 'Sites',
  site: 'Site',
  door: 'Door',
  add_site: 'New Site',
  edit_site: 'Site',
  edit_site_access: 'Edit Site Access',
  search_site: 'Search Site',
  copy_organization: 'Copy Account',
  delete_site_message: 'Are you sure you want to delete {{site}}?',
  notifications: 'Notifications',
  notification: 'Notification',
  events: 'Events',
  site_info: 'Site Information',
  total_sites_assigned: 'Total sites assigned',
  alerts: 'Alerts',
  add_notification: 'New Notification',
  send_to: 'Send to',
  delete_contact_message:
    'Are you sure you want to delete {{contact}} notification contact?',
  invalid_email: 'Invalid email format',
  select_unselect_all: 'Select/Unselect All',
  notification_at_least_one: 'Select at least one notification',
  form: {
    name: 'Name',
    address: 'Address',
    country: 'Country',
    city: 'City',
    state: 'State',
    zip_code: 'Zipcode',
    timezone: 'Timezone',
    contact_to: 'Contact to',
    type_email: 'Type email',
    send_notification: 'Send notification',
    lockdown: 'Lockdown (started, stopped)',
    alarm_forced: 'Door in alarm forced',
    alarm_tampared: 'Door in alarm tampered',
    tamper: 'Tamper',
    battery_troubles: 'Battery troubles',
    power_troubles: 'Power troubles',
  },
  table: {
    name: 'NAME',
    address: 'ADDRESS',
    country: 'COUNTRY',
    city: 'CITY',
    state: 'STATE',
    zipcode: 'ZIPCODE',
    timezone: 'TIMEZONE',
  },
};

export default general;
