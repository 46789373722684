const doors = {
  doors: 'Doors',
  search_door: 'Search Door',
  search_event: 'Search Event',
  configuration: 'Configuration',
  events: 'Events',
  notes: 'Notes',
  basic: 'Basic',
  advanced: 'Advanced',
  side_b: 'Exit Side',
  side_a: 'Entry Side',
  options: 'Options',
  schedule: 'Schedule',
  timing: 'Timing',
  user_location: 'User Location',
  update_user_location: 'Update User Location On',
  timing_user_location: 'Timing User Location On',
  edit: 'Edit',
  edit_basic: 'Edit-Basic',
  edit_advanced: 'Edit-Advanced',
  door_commands: 'Door Commands',
  legend_title: 'Legend',
  restore_tamper_alarm: 'Restore tamper alarm',
  no_tampered_msg: 'The door is not tampered',
  lockdown_grant: 'Lockdown grant access',
  no_lockdown_msg: 'The door is not on lockdown',
  total_active_doors: 'Total active doors',
  form: {
    serial: 'Serial',
    door: 'Door',
    active: 'Active',
    state: 'State',
    lock_status: 'Lock status',
    access_status: 'Access status',
    grant_access: 'Grant access',
    disable_access: 'Disable access',
    type_here: 'Type here',
    name: 'Name',
    controller_name: 'Controller name',
    unlock_schedule: 'Unlock schedule',
    unlock_first: 'Unlock on first access/first man in',
    relock_door_open: 'Relocks on door open',
    relock_door_closed: 'Relocks on door closed',
    left_open: 'Can be left open',
    double_swipe: 'Double swipe',
    access_granted: 'Access granted',
    doors_open: 'Doors open',
    unlock_time: 'Unlock time (seconds)',
    extended_unlock: 'Extended unlock (seconds)',
    open_prealarm: 'Open too long pre-alarms (seconds)',
    open_alarm: 'Open too long alarms (seconds)',
    rex: 'REX',
    reader: 'Reader',
    contact_input: 'Contact Input',
    lock: 'Lock',
    cards: 'Credentials',
    pin: 'and Pin',
    keypad_code: 'Keypad code',
    door_open: 'And even if door open',
    interlock_side: 'Interlock on this side',
    two_man_rule: 'Two man rule (chape)',
    pin_schedule: 'Pin schedule',
    rex_schedule: 'REX schedule',
    unlock_options: 'Unlock options',
    card_only: 'Card only',
    keypad_only: 'Keypad only',
    card_or_keypad: 'Card or keypad',
    card_and_keypad: 'Card and keypad',
    none: 'None',
  },
  table: {
    serial: 'SERIAL',
    door: 'DOOR',
    active: 'ACTIVE',
    state: 'STATE',
    lock_status: 'LOCK STATUS',
    access_status: 'ACCESS STATUS',
    grant_access: 'GRANT ACCESS',
    disable_access: 'DISABLE ACCESS',
    date_time: 'DATE/TIME',
    description: 'DESCRIPTION',
    object: 'OBJECT',
    instigator: 'INSTIGATOR',
  },
  legend: {
    closed: 'Closed',
    pre_alarm: 'Pre-alarm',
    alarm: 'Alarm',
    locked: 'Locked',
    unlocked: 'Unlocked',
    unlocked_schedule: 'Unlocked schedule',
    access_disabled: 'Access disabled',
    trouble: 'Trouble',
    open: 'Open',
    alarm_forced: 'Alarm / Forced',
    locked_operator: 'Lock (Override)',
    unlocked_operator: 'Unlock (Override)',
    access_enabled: 'Access enabled',
    unknown: 'Unknown',
    door_tampered: 'In alarm tampered',
    offline: 'Offline',
    tampered_lockdown: 'Tampered / Lockdown',
  },
};

export default doors;
