import React, { ReactNode, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

interface NavigationScrollProps {
  children: ReactNode;
}

const NavigationScroll: React.FC<NavigationScrollProps> = ({ children }) => {
  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [pathname]);

  return <>{children}</>;
};

export default NavigationScroll;
