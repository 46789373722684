import { ActionTag } from 'interfaces/permissions.interface';
import { store } from 'store';
import { UserReducerState } from 'store/slices/user';

/**
 * Check if the user has permission for a given tag.
 * @param tag The action tag to check.
 * @returns A boolean indicating if the user has permission for the tag.
 */
export const hasPermissionForTag = (tag: ActionTag) => {
  const { user, actionMap } = store.getState().user as UserReducerState;

  if (!user) return false;

  const organizationId = user.selectedOrg?.organization;
  const selectedSiteId = user.selectedOrg?.selectedSite?.site;
  const role = user.role;
  const profileType = user.profile_type;

  // In case someone overrides the type-check for the tag
  const normalizedTag = tag.trim().toLowerCase();

  /**
   * As organization profiles don't have a role, when there isn't
   * a site selected, use a combination of the roles on its sites
   * to determine if the user has permission.
   */
  if (profileType === 'organization' && !role && !selectedSiteId) {
    const roles =
      user.sites
        ?.filter((site) => site.organization === organizationId)
        .map((site) => site.role) || [];

    return roles.some((r) => actionMap[r]?.[normalizedTag]);
  }

  return actionMap[role]?.[normalizedTag] ?? false;
};
