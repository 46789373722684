export const lightTypography = (fontFamily: string) => {
  return {
    fontFamily: fontFamily,

    h6: {
      fontSize: '0.75rem',
      fontWeight: 500,
    },
    h5: {
      fontSize: '0.875rem',
      fontWeight: 500,
    },
    h4: {
      fontSize: '1rem',
      fontWeight: 600,
    },
    h3: {
      fontSize: '1.25rem',
      fontWeight: 600,
    },
    h2: {
      fontSize: '1.5rem',
      fontWeight: 700,
    },
    h1: {
      fontSize: '2.125rem',
      fontWeight: 700,
    },
    subtitle1: {
      fontSize: '0.9rem',
      fontWeight: 500,
      color: '#616161',
      lineHeight: '1.334em',
    },
    subtitle2: {
      fontSize: '0.75rem',
      fontWeight: 400,
      color: '#2196f3',
    },
    caption: {
      fontSize: '0.75rem',
      color: '#616161',
      fontWeight: 400,
    },
    body1: {
      fontSize: '0.875rem',
      lineHeight: '1.334em',
    },
    body2: {
      letterSpacing: '0em',
      fontWeight: 400,
      lineHeight: '1.5em',
    },
    button: {
      textTransform: 'capitalize',
    },
  };
};
