const pages = {
  access_levels: {
    create: "Créer un niveau d'accès",
    edit: "Éditer un niveau d'accès",
  },
  credentials: {
    create: 'Créer un identifiant',
    edit: 'Éditer un identifiant',
  },
  users: {
    create: 'Créer un utilisateur',
    edit: 'Éditer un utilisateur',
  },
};

export default pages;
