import * as actions from '../actions/hardware-inputs';
import { AnyAction } from '@reduxjs/toolkit';

interface State {
  inputs: any[];
}

const initialState: State = {
  inputs: [],
};

const hardwareInputsReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case actions.SET_INPUTS:
      return {
        ...state,
        inputs: [...action.payload],
      };

    case actions.UPDATE_INPUT: {
      const updatedInputs = state.inputs.map((input) => {
        return input.inputlogic === action.payload.inputlogic
          ? action.payload
          : input;
      });

      return {
        ...state,
        inputs: updatedInputs,
      };
    }

    default:
      return state;
  }
};

export default hardwareInputsReducer;
