import { createTheme, ThemeOptions } from '@mui/material/styles';

import colors from '../assets/scss/_themes-vars.module.scss';

import componentStyleOverrides from './compStyleOverride';

import { lightPalette } from './lightPalette';

import { lightTypography } from './lightTypography';

export const theme = () => {
  const color = colors;

  const fontFamily = [
    'Lato',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(',');

  const lightTheme = {
    typography: lightTypography(fontFamily),
    palette: lightPalette({
      colors: color,
      heading: color.grey900,
      paper: color.paper,
      backgroundDefault: color.secondaryLight,
      background: color.primaryLight,
      darkTextPrimary: color.grey700,
      darkTextSecondary: color.grey500,
      textDark: color.grey900,
      menuSelected: color.secondaryDark,
      menuSelectedBack: color.secondaryLight,
      divider: color.grey200,
    }),
  };

  const themes = {
    light: createTheme(lightTheme as ThemeOptions),
  };

  themes.light.components = componentStyleOverrides(lightTheme);

  return themes;
};

export default theme;
