const cards = {
  cards: 'Credentials',
  search_cards: 'Search Credential / Display Name ',
  add_card: 'New Credential',
  update_card: 'Credential',
  options: 'Options',
  general_information: 'General Information',
  assign_user: 'Assign User',
  assigned_user: 'Assigned User',
  assign_msg: 'Do you want to assign the credential {{ card }} to {{ user }}?',
  assign_reader:
    'Do you want to scan with reader {{ reader }} from site {{ site }}?',
  unassign_user_msg:
    'Do you want to unassign {{ user }} from the credential {{ card }}?',
  delete_card_multiple_sites:
    'NOTE: This credential is associated with more than one site, it will be deleted from all of them.',
  readers: 'Readers',
  scan_card: 'Scan Credential',
  search_devices: 'Search Devices',
  scan_status: 'Scan Status: ',
  enrolled: 'Enrolled',
  discover: 'Discover KPROG',
  use_kprog: 'Use KPROG',
  kprog: 'KPROG',
  table: {
    card: 'CREDENTIAL',
    format: 'FORMAT',
    name: 'NAME',
    state: 'STATUS',
    assigned: 'ASSIGNED',
    user_name: 'NAME',
    reader: 'READER',
    site: 'SITE',
  },
  form: {
    display_name: 'Display Name',
    facility_code: 'Facility Code',
    card_code: 'Credential Code',
    activation_date: 'Activation Date',
    expiry_date: 'Expiration Date',
    card_format: 'Credential Format',
    card_number_hex: 'Credential Number Hex',
    all: 'All',
    assigned: 'Assigned',
    unassigned: 'Unassigned',
    used_for_access: 'Used for Access',
    stolen: 'Stolen',
    lost: 'Lost',
    status: 'Status',
    active: 'Active',
    select_a_reader: 'Select a reader',
    csn: 'C.S.N. (printed number)',
    registration_code: 'Registration Code',
  },
  pin_option: {
    not_used: 'Not used',
    start_lockdown: 'Start lockdown',
    stop_lockdLock: 'Stop lockdown',
    lockdown_grant: 'Lockdown grants access',
    area_secured: 'Keep door secured (maintain lockdown)',
  },
};

export default cards;
