const users = {
  header: 'Lista de Usuarios',
  users: 'Usuarios',
  credentials: 'Credenciales',
  view_roles: 'Ver Detalles de Rol',
  edit_user: 'Usuario',
  user_details: 'Detalles del Usuario',
  add_user: 'Nuevo Usuario',
  search_user: 'Buscar Usuario',
  delete_user_message:
    '¿Está seguro de que desea eliminar a {{user}}? <br> Si el usuario tiene cartas asociadas estas también serán desasignadas.',
  delete_morethan_one_site:
    'NOTA: Este usuario está asociado con más de un sitio, se eliminará de todos ellos.',
  selected_users: 'los usuarios seleccionados',
  confirm_modal_cancel: 'No, cancelar',
  confirm_modal_confirm: 'Sí, eliminar',
  remove_login: 'Eliminar inicio de sesión',
  create_login: 'Crear inicio de sesión',
  all_doors: 'Todas las puertas',
  select_doors: 'Seleccionar puertas',
  remove_confirmation_msg:
    '¿Está seguro de que desea eliminar el acceso web del usuario?',
  activate_confirm_msg:
    '¿Estás seguro de que quieres {{action}} a {{user}} para la cuenta {{account}}?',
  role_confirm_msg:
    '¿Estás seguro de que quieres establecer el rol de {{role}}',
  access_levels: 'Niveles de acceso',
  set_keypad: 'Colocar Keypad Code',
  has_keypad: 'Tiene Keypad',
  no_keypad: 'No tiene Keypad',
  remove_keypad: 'Eliminar Código de Teclado',
  remove_keypad_msg:
    '¿Está seguro de que desea eliminar el código de teclado asignado a {{user}}?',
  pending_changes:
    'Hay cambios pendientes para guardar. ¿Estás seguro de que quieres abandonar esta página?',
  no_custom_fields:
    'No hay campos personalizados configurados para este sitio.',
  remove_last_site_note:
    'NOTA: Eliminar el acceso a este sitio también eliminará al usuario de toda la cuenta.',
  remove_last_site_with_access_note:
    'NOTA: No tiene acceso a los sitios restantes de este usuario. Eliminar el acceso a este sitio también eliminará al usuario de su lista de usuarios.',
  change_site_status:
    '¿Estás seguro de que quieres cambiar el acceso a {{site}} de este usuario a {{status}}?',
  site_access: 'Acceso a sitio',
  general_information: 'Información general',
  custom_fields: 'Campos personalizados',
  custom_fields_success: 'Campos personalizados guardados con éxito',
  unassign: 'Desasignar',
  unassign_site_msg:
    'Si el usuario tiene credenciales asociadas, estas también se desasignarán para este sitio, ¿está seguro de que desea desasignar a este usuario de {{site}}?',
  unassign_confirm: 'Sí, desasignar',
  unassign_cancel: 'No, cancelar',
  unassign_tooltip: 'Desasignar usuario del sitio',
  delete_tooltip: 'Eliminar usuario de la cuenta',
  access_rights: 'Derechos de acceso',
  assign: 'Asignar',
  table: {
    first_name: 'NOMBRE',
    last_name: 'APELLIDO',
    email: 'CORREP',
    user: 'USUARIO',
    role: 'ROL',
    phone: 'TELÉFONO',
    username: 'NOMBRE DE USUARIO',
    last_login: 'ÚLTIMO INICIO DE SESIÓN',
    serial_number: 'NÚMERO DE SERIE',
    ip: 'DIRECCIÓN IP',
    web_port: 'PUERTO',
    status: 'ESTADO',
    firmware_version: 'VERSIÓN DE FIRMWARE',
    company: 'EMPRESA',
    name: 'NOMBRE',
    website: 'SITIO WEB',
    country: 'PAÍS',
    timezone: 'ZONA HORARIA',
    address: 'DIRECCIÓN',
    access_level: 'NIVEL DE ACCESO',
    activation_date: 'FECHA DE ACTIVACIÓN',
    expiry_date: 'FECHA DE EXPIRACIÓN',
    act_exp_date: 'FECHA DE ACTIVACIÓN / EXPIRACIÓN',
    keypad_code: 'CÓDIGO DE TECLADO',
    organization_company_name: 'ORGANIZACION',
    dealer_company_name: 'PROVEEDOR',
  },
  form: {
    username: 'Nombre de Usuario',
    email: 'Correo Electrónico',
    phone_number: 'Número de Teléfono',
    date_created: 'Fecha de Creación',
    date_updated: 'Fecha de Actualización',
    first_name: 'Nombre',
    last_name: 'Apellido',
    new_password: 'Nueva Contraseña',
    role: 'Rol',
    select_role: 'Seleccionar Rol',
  },
  profile: {
    username: 'Usuario',
    full_name: 'Nombre Completo',
    last_login: 'Ultimo Inicio de Sesión',
    actions: 'Acciones',
    location: 'Ubicación',
    device: 'Dispositivo',
    ip_address: 'Dirección IP',
    date_time: 'Fecha/Hora',
    edit_profile: 'Editar Perfil',
    profile: 'Detalles de Usuario',
    cancel: 'Cancelar',
    update: 'Actualizar',
    login: 'Inicio de Sesión',
    activities: 'Actividades',
    refresh: 'Refrescar',
    change_email: 'Cambiar Email',
    send_code: 'Enviar Código',
    new_email: 'Nuevo email',
    confirm_email: 'Confirmar email',
    change_email_description:
      'Haga clic en enviar código para recibir un código de confirmación',
    code: 'Código',
    code_expiration: 'El código expira en 10 minutos',
    send_new_code: 'Enviar nuevo código',
    change: 'Cambiar',
    user_updated: 'El perfil de usuario ha sido actualizado',
    allowed_filetypes: 'Tipos de archivos permitidos: png, jpg, jpeg.',
    change_avatar: 'Cambiar avatar',
    remove_avatar: 'Eliminar avatar',
    avatar_modal_description: '¿Seguro de que quieres eliminar tu avatar?',
    login_sessions: 'Sesiones de inicio de sesión',
  },
  add: {
    user_information: 'Informacion de usuario',
    security: 'Seguridad',
    login: 'Inicio de sesión',
    first_name: 'Nombre',
    last_name: 'Apellido',
    country: 'País',
    city: 'Ciudad',
    state: 'Estado',
    zipcode: 'Código postal',
    address: 'Direccion',
    phone: 'Teléfono',
    mobile: 'Móvil',
    email: 'Correo electrónico',
    card: 'Credenciales',
    assign_card: 'Asignar credenciales',
    successfully_created: 'creado exitosamente',
    none: 'Ninguno',
    create_login: 'Crear inicio de sesión',
    site: 'Sitio',
    first_registration: 'Primera inscripción',
    coming_from_another_site: 'Procedente de otro sitio',
    user_found: '¡Usuario encontrado!',
    no_user_found:
      'No se ha encontrado ningún usuario asociado a esta credencial, por favor utilice otra tarjeta.',
    use_this_user: 'Utilice este usuario',
    no_reader: 'Sin lector',
  },
  security: {
    allow_extend_time: 'Permitir extender el tiempo',
    override_anti_passback: 'Anular Anti-Passback',
    can_arm: 'Puede Armar',
    master_attribute_card_enrollment_mode:
      'Atributo maestro - Modo de registro de Credencial',
    overrides_interlock: 'Anular Interlock',
    can_disarm: 'Puede Desarmar',
    activation_date: 'Fecha de Activación',
    expiration_date: 'Fecha de Expiración',
    keypad_code: 'Código de teclado',
    confirm_code: 'Código de confirmación',
    access_level: 'Nivel de Acceso',
    site: 'Sitio',
    all: 'Todos',
    no_access_levels: 'No hay niveles de acceso',
    options: 'Opciones',
  },
  login: {
    create_login: 'Crear inicio de sesión',
    update_login: 'Actualizar inicio de sesión',
    username: 'Nombre de usuario',
    last_login: 'Ultimo inicio de sesión',
    status: 'Estado',
    access_to_all_sites: 'Acceso a todos los sitios',
    select_sites: 'Seleccionar sitios',
    success: 'Usuario creado exitosamente',
    error: 'No se pudo crear el usuario',
    web_access: 'Acceso Web',
    no_role: 'Sin rol',
    no_access: 'Sin acceso',
    web_access_tooltip: 'El acceso web se otorga al seleccionar un rol',
    define_email:
      'Define una dirección de correo electrónico para este usuario antes de asignarle un rol.',
    set_as_admin: 'Establecer como {{role}}',
    set_as_admin_success: 'Usuario establecido como {{role}} exitosamente',
    remove_web_access: 'Remover Acceso Web',
    remove_web_access_success: 'Acceso web de usuario removido exitosamente',
    assign_role_success: 'Rol asignado exitosamente',
    remove_role_success: 'Rol removido exitosamente',
  },
  card: {
    assign_a_card: 'Asignar una credencial',
    edit_card: 'Editar tarjeta',
    credential: 'Credencial',
    due_date: 'Fecha de vencimiento',
    no_due_date: 'Sin fecha de vencimiento',
    assign: 'Asignar',
    activation_date: 'Fecha de activacion',
    expiry_date: 'Fecha de caducidad',
    card_status: 'Estado',
    actions: 'Acciones',
    active: 'Activa',
    inactive: 'Inactiva',
    lost: 'Perdida',
    stolen: 'Robada',
    unassign_confirmation: '¿Esta seguro que desea desasignar esta tarjeta',
    to: 'a',
    format: 'Formato',
    card_number: 'Número de credencial',
    assign_card_confirm:
      '¿Quiere asignar la Credencial {{number}} a {{username}}?',
    confirm_btn: 'Confirmar',
    cancel_btn: 'Cancelar',
    display_name: 'Nombre para mostrar',
  },
  delete: {
    success: 'El usuario {{name}} fue eliminado exitosamente',
    error: 'No se pudo eliminar',
  },
  error: {
    invalid_email: 'Formato de correo electrónico no válido',
    invalid_date: 'Formato de fecha no válido',
    email_no_match: 'Email no coincide',
    update_failed: 'Actualización Fallida',
  },
  events: {
    tabTitle: 'Eventos',
  },
  keypad: {
    success: 'Código de teclado actualizado exitosamente',
    error: 'No se pudo actualizar el código de teclado',
    remove_confirm: '¿Está seguro de que desea eliminar el código de teclado?',
    remove_success: 'Código de teclado eliminado exitosamente',
    remove_error: 'No se pudo eliminar el código de teclado',
    keypad_length: 'Se requieren {{length}} caracteres',
    keypad_var_length:
      'Mínimo {{minimum}} / Máximo {{maximum}} caracteres de longitud',
    code_mismatch: 'El código del teclado debe coincidir',
    custom: 'Código de teclado personalizado',
    generate_new: 'Generar nuevo código de teclado',
    ask: '¿Desea establecer el código de teclado para el sitio {{site}}?',
  },
};

export default users;
