const holidays = {
  holidays: 'Holidays',
  search_holiday: 'Search Holiday',
  add_holiday: 'New Holiday',
  update_holiday: 'Holiday',
  modal_general: 'General',
  modal_events: 'Events',
  form: {
    name: 'Name',
    occurrence: 'Occurrence',
    month: 'Month',
    day: 'Day',
    occurs: 'Occurs',
    year: 'Year',
    duration_days: 'Duration Days',
    hours: 'Hours',
    minutes: 'Minutes',
  },
  table: {
    holiday: 'HOLIDAY',
    state: 'STATE',
    date: 'DATE',
    duration: 'DURATION',
    is_yearly: 'IS YEARLY',
    is_relative: 'IS RELATIVE DATE',
    relative_week_day: 'RELATIVE WEEK DAY',
  },
};

export default holidays;
