import EmailIcon from '@mui/icons-material/EmailOutlined';
import { Avatar, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { useUser } from 'hooks/useUser';
import profilePlaceholderImg from 'assets/images/misc/avatars/profile_placeholder.png';
import Modal from 'components/Modal/Modal';
import { ProfileType, UserProfile } from 'interfaces/user.interface';
import ProfileTable from './ProfileTable/ProfileTable';

type ProfileMap = Record<ProfileType, UserProfile[]>;

type Props = {
  open: boolean;
  onClose: () => void;
};

const SwitchProfile = ({ open, onClose }: Props) => {
  const { t } = useTranslation();
  const user = useUser();

  const profiles = user.user_profiles.reduce(
    (acc: ProfileMap, profile: UserProfile) => {
      if (!acc[profile.profile_type]) {
        acc[profile.profile_type] = [];
      }
      acc[profile.profile_type].push(profile);
      return acc;
    },
    {} as ProfileMap,
  );

  return (
    <Modal
      width="md"
      open={open}
      onClose={onClose}
    >
      <Typography
        variant="h2"
        marginBottom={2}
      >
        {t('switch.welcome', { name: user.first_name })}
      </Typography>

      <Typography variant="h2">{t('switch.please_select')}</Typography>

      <Stack
        direction="row"
        spacing={2}
        marginTop={2}
      >
        <Avatar
          variant="rounded"
          sx={{ width: 100, height: 100 }}
        >
          <img
            src={profilePlaceholderImg}
            alt={`${user.first_name} ${user.last_name}`}
            style={{ width: '100px', height: '100px' }}
          />
        </Avatar>

        <Box width={'100%'}>
          <Typography variant="h3">
            {user.first_name} {user.last_name}
          </Typography>
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            marginBottom={4}
          >
            <EmailIcon />
            <Typography variant="h4">{user.email}</Typography>
          </Stack>

          {Object.keys(profiles).map((profileType) => (
            <ProfileTable
              key={profileType}
              user={user}
              profiles={profiles[profileType as ProfileType]}
              onProfileChange={onClose}
            >
              <Typography variant="h3">
                {t(`switch.as_${profileType}`)}
              </Typography>
            </ProfileTable>
          ))}
        </Box>
      </Stack>
    </Modal>
  );
};

export default SwitchProfile;
