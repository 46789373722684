// Interfaces
import { OpenSession } from 'interfaces/supportTool.interface';

// Actions
export const SET_CURRENT_SESSION = 'SET_CURRENT_SESSION';

// Action creators
export const setCurrentSession = (session: OpenSession | undefined) => ({
  type: SET_CURRENT_SESSION,
  session,
});
