const holidays = {
  holidays: 'Vacances',
  search_holiday: 'Recherche Vacances',
  add_holiday: 'Nouvelles vacances',
  update_holiday: 'Vacances',
  modal_general: 'Général',
  modal_events: 'Événements',
  form: {
    name: 'Nom',
    occurrence: 'Occurrence',
    month: 'Mois',
    day: 'Jour',
    occurs: 'Se produit',
    year: 'An',
    duration_days: 'Durée Jours',
    hours: 'Les heures',
    minutes: 'Minutes',
  },
  table: {
    holiday: 'VACANCES',
    state: 'ETAT',
    date: 'DATE',
    duration: 'DURÉE',
    is_yearly: 'EST ANNUEL',
    is_relative: 'EST UNE DATE RELATIVE',
    relative_week_day: 'JOUR DE SEMAINE RELATIF',
  },
};

export default holidays;
