import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import user, { UserProfile } from 'interfaces/user.interface';
import { dateFormat } from '../../../../helpers/dateUtils';
import ProfileSwitcherLink from 'components/ProfileSwitcherLink/ProfileSwitcherLink';

interface Props {
  user: user;
  profiles: UserProfile[];
  children: React.ReactNode;
  onProfileChange: () => void;
}

const ProfileTable = ({ user, profiles, children, onProfileChange }: Props) => {
  const { t } = useTranslation();

  const isProfileActive = (profile: UserProfile) => {
    return user.currentProfile === profile.dealer_org_id;
  };

  return (
    <Box marginBottom={4}>
      {children}
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('switch.name')}</TableCell>
              <TableCell>{t('switch.last_login')}</TableCell>
              <TableCell>{t('switch.actions')}</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {profiles.map((profile) => (
              <TableRow key={profile.dealer_org_id}>
                <TableCell>
                  <Typography
                    fontWeight={isProfileActive(profile) ? 'bold' : 'normal'}
                  >
                    {profile.company_org_name}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    fontWeight={isProfileActive(profile) ? 'bold' : 'normal'}
                  >
                    {profile.last_login ? dateFormat(profile.last_login) : '-'}
                  </Typography>
                </TableCell>
                <TableCell>
                  <ProfileSwitcherLink
                    profile={profile}
                    onProfileChange={onProfileChange}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ProfileTable;
