const support_tool = {
  // New session
  new_session: 'Nueva sesión de soporte',
  select_user_type: 'Selecciona el tipo de usuario para dar soporte',
  sent_code_to:
    'Para continuar, completa este paso de verificación. Hemos enviado un código a',
  please_enter: '. Por favor, introdúcelo abajo.',
  account_success: 'Sesión creada con éxito para la cuenta {{account}}',
  dealer_success:
    'Sesión creada con éxito para el usuario distribuidor {{dealer}}',
  otp_verification: 'Verificación de OTP',
  new_account_session_confirmation:
    '¿Estás seguro de que quieres crear una nueva sesión para la cuenta {{account}}?',
  new_dealer_session_confirmation:
    '¿Estás seguro de que quieres crear una nueva sesión para el usuario distribuidor {{dealer}}?',

  // Open sessions
  open_sessions: 'Sesiones abiertas',
  search_sessions: 'Buscar sesiones',
  session_type: 'Tipo de sesión',
  support_email: 'Correo de soporte',
  end_session: 'Finalizar sesión',
  end_session_confirmation: '¿Estás seguro de que quieres finalizar la sesión?',
  session_ended: 'Sesión finalizada con éxito',
  session_code: 'Código de sesión',
  session_code_msg:
    'No eres el propietario de esta sesión. Para abrirla, pide al propietario el código de la sesión e introdúcelo abajo.',
  session_opened: 'Sesión abierta con éxito',

  // Session window
  select_account: 'Selecciona una cuenta',
  support_session: 'Sesión de soporte',
  switch_account: 'Cambiar cuenta',
  session_details: 'Detalles de la sesión',
  support_case: 'Caso de soporte',
  support_agent: 'Agente de soporte',
  contact_name: 'Nombre de contacto',
  contact_number: 'Número de contacto',
  controller: 'Controlador',
  sdk_entities: 'Entidades SDK',
  unsynced: 'No sincronizado',
  tasks: 'Tareas',
  no_session_msg:
    'Actualmente no hay ninguna sesión abierta. Por favor, abre una sesión para continuar',

  // Diagnose Controller
  get_information: 'Obtener información',
  diagnose_controller: 'Diagnóstico - Controlador',
  serial_number: 'Número de Serie',
  last_alive: 'Último activo',
  status_ping: 'Estado Ping',
  restore_global_certificate: 'Restaurar Certificado Global',
  create_aws_thing: 'Crear Cosa AWS',
  open_support_case: 'Abrir un caso de soporte',
  server_status: 'Estado Servidor',
  frontend: 'Frontend',
  backend: 'Backend',
  database: 'Base de Datos',
  iot_services: 'Servicios IOT',
  controller_details: 'Detalles del Controlador',
  controller_status: 'Estado del Controlador',

  // User types
  dealer: 'Distribuidor',
  account: 'Cuenta',

  // Controller Tab
  site_name: 'Nombre del sitio',
  status: 'Estado',
  sync_status: 'Estado de sincronización',
  firmware_version: 'Versión del firmware',
  online: 'En línea',
  offline: 'Desconectado',
  synced: 'Sincronizado',
  unknown: 'Desconocido',
  sync: 'Sincronizar',
  firmware: 'Firmware',
  sdk: 'SDK',
  syncing: 'Sincronizando...',
  checking: 'Comprobando...',
  sync_confirmation:
    '¿Estás seguro de que quieres sincronizar el controlador {{controller}}? Esta acción puede tardar unos minutos.',
  sync_successfull: 'Controlador sincronizado con éxito',

  // SDK Controller
  module_serial: 'Serial del módulo',
  ping: 'Ping',
  edit_cloud: 'Editar la nube',
  object_type: 'Tipo de objeto',
  sub_module: 'Sub - Módulo',
  command: 'Comando',
  record: 'Registro',
  options: 'Opciones',
  command_tasks: 'Tareas de comando',
  command_task_details: 'Detalles de la tarea de comando',
  entities_table: 'Tabla de entidades',
  sending_command: 'Enviando comando...',
  open_cloud: 'Abrir nube',
  description: 'Descripción',
  tag: 'Etiqueta',
  sync_cloud: 'Sincronización nube',
  sync_controller: 'Sincronización controlador',
  in_memory: 'En memoria',
  id: 'ID',
  id_from: 'ID desde',
  id_to: 'ID hasta',
  max: 'Máximo',
  no_command_task_selected: 'Ninguna tarea de comando seleccionada',
  response: 'Respuesta',
  request: 'Solicitud',
  created_at: 'Creado en',
  created: 'Creado',
  started_at: 'Iniciado en',
  started: 'Iniciado',
  pending: 'Pendiente',
  finished: 'Finalizado',
  type: 'Tipo',
  date: 'Fecha',
  entity: 'Entidad',
  tag_name: 'Nombre de etiqueta',
  cloud_data: 'Datos de la nube',
  controller_data: 'Datos del controlador',

  // SDK Entities
  search_by_id: 'Buscar por ID',
  search_by_name: 'Buscar por Nombre de Campo',
  show_details: 'Mostrar detalles',
  per_record: 'Por registro',
  per_controller: 'Por controlador',
  search: 'Buscar',
  from_id: 'Desde ID',
  to_id: 'Hasta ID',
  field_name: 'Nombre de Campo',
  value: 'Valor',
  label: 'Etiqueta',
  object_status: 'Estado del Objeto',
  view: 'Ver',
  push: 'Enviar',
  view_sdk: 'Ver SDK',
  save_push: 'Guardar y Enviar',
  entity_id: 'ID de Entidad',
  entity_sync: 'Sincronización de Entidad',
  entity_data: 'Datos de la entidad',
  records: 'Registros',
  copy: 'Copiar',
  paste: 'Pegar',
  view_entity: 'Ver entidad',
  push_successfull: 'Datos de la entidad enviados con éxito',
  pasted_data: 'Datos pegados',
  paste_confirmation:
    '¿Estás seguro de que quieres pegar los siguientes datos?',
  paste_error: 'Error al pegar los datos',
  copy_success: 'Datos copiados con éxito',
  copy_error: 'Error al copiar los datos',

  // Unsynced Tab
  name: 'Nombre',
  read: 'Leer',
  site: 'Sitio',
  sync_count_cloud: 'Sincronizar Conteo Cloud',
  sync_count_controller: 'Sincronizar Conteo Controller',
  sync_count: 'Sincronizar Conteo',
};

export default support_tool;
