const breadcrumbs = {
  accessLevels: "Niveaux d'accès",
  accounts: 'Comptes',
  controllers: 'Contrôleurs',
  credentials: 'Identifiants',
  dealerInfo: 'Informations sur le revendeur',
  doors: 'Portes',
  events: 'Événements',
  firmwareManager: 'Gestionnaire de firmware',
  floorPlans: "Plans d'étage",
  globalSettings: 'Paramètres globaux',
  hardware: 'Hardware',
  holidays: 'Jours fériés',
  inputs: 'Entrées',
  interlocks: 'Interverrouillages',
  lockdowns: 'Confinements',
  notifications: 'Notifications',
  outputs: 'Sorties',
  reports: 'Rapports',
  schedules: 'Horaires',
  settings: 'Paramètres',
  sites: 'Sites',
  users: 'Utilisateurs',

  create: 'Créer',
  edit: 'Modifier',
};

export default breadcrumbs;
