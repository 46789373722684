const general = {
  controller: 'Manette',
  add_controller: 'Nouveau Contrôleur',
  edit_controller: 'Contrôleur',
  search_controller: 'Recherche le Contrôleur ',
  search_serial_number: 'Rechercher le numéro de série',
  register_msg:
    'Connectez le premier contrôleur au réseau, remplissez le numéro de série et appuyez sur enregistrer pour continuer',
  serial_number: 'Numéro de série',
  new_serial_number: 'Nouveau Numéro de série',
  site: 'Placer',
  register: 'Enregistrer',
  setup: 'Installation',
  doors: 'Des portes',
  doors_activation: 'Activation des portes',
  finish: 'Finir',
  wizard: 'Assistant',
  run_wizard: "Assistant d'exécution",
  reboot: 'Redémarrer',
  controller_wizard: 'Assistant placer',
  access_level: "Niveau d'accès",
  user_cards: 'Utilisateur & Cartes',
  remove_controller_msg: 'Voulez-vous supprimer le contrôleur',
  enable_door: 'Etes-vous sûr de vouloir activer la porte {{door}}?',
  disable_door: 'Etes-vous sûr de vouloir désactiver la porte',
  confirm_selected_controller:
    'Etes-vous sûr de vouloir enregistrer ce contrôleur {{controller}} sur le site {{site}}',
  confirm_delete_firmware_available:
    'Êtes-vous sûr de vouloir supprimer la version du firmware {{version}}?',
  choose_controller_msg:
    'Choisissez un contrôleur et cliquez pour vous inscrire',
  processing_controller: 'Traitement du nouveau contrôleur sur le réseau.',
  reboot_message: 'Redémarrage du contrôleur. Veuillez patienter 60 secondes:',
  door_info_message:
    'Récupération des informations sur la porte. Veuillez patienter 60 secondes :',
  no_door_site_msg: 'Pas de portes à afficher',
  no_accesslvl_site_msg: "Aucun niveau d'accès à afficher",
  open_wizard_msg: "Voulez-vous ouvrir l'assistant du placer?",
  rebooting_controller: 'Redémarrage du contrôleur',
  form: {
    enable_ip: 'Activer la configuration IP',
    dns: 'DNS IP',
    use_dhcp: 'Utiliser DHCP',
    subnet_mask: 'Masque de sous-réseau',
    ip_address: 'Adresse IP',
    webport: 'Port Internet',
    username: "Nom d'utilisateur",
    password: 'Mot de passe',
    name: 'Nom',
    address: 'Adresse',
    country: 'Pays',
    city: 'Ville',
    state: 'État',
    zip_code: 'Code postal',
    timezone: 'Fuseau horaire',
    ip_format_error: 'Format incorrect (xxx.xxx.xxx.xxx)',
    mac_address: 'Adresse Mac',
    gateway: 'Passerelle',
    serial_number: 'Numéro de série',
    setup_error:
      "Une erreur s'est produite lors de la configuration du contrôleur, veuillez réessayer",
    showTampered: 'Afficher les options de manipulation',
    tabControllerData: 'Données du contrôleur',
    tabControllerTamper: 'Altérer',
  },
  table: {
    name: 'NOM',
    address: 'ADRESSE',
    country: 'PAYS',
    city: 'VILLE',
    state: 'ÉTAT',
    zipcode: 'CODE POSTAL',
    timezone: 'FUSEAU HORAIRE',
  },
  register_steps: 'Étape {{step}} / 6',
  register_steps_alt: 'Étape {{step}} / 7',
  steps: {
    step1: 'Contrôleur de ping',
    step2: "Récupérer le code d'enregistrement",
    step3: 'Attribuer au site cloud',
    step4: 'Génération des identifiants',
    step5: 'Tester les identifiants',
    step6: 'Connecté!',
    step7: 'Attachement au site',
  },
  online: 'En ligne',
  offline: 'Hors ligne',
  available_doors: '{{doors}} portes disponibles',
  register_error:
    "Une erreur s'est produite sur l'une des étapes, veuillez réessayer",
  errors: {
    ping: "Une erreur s'est produite lors du ping du contrôleur, veuillez vérifier le numéro de série",
    verification_code:
      "Une erreur s'est produite lors de la récupération du code de vérification, veuillez vérifier la connexion du contrôleur",
    assign:
      "Une erreur s'est produite lors de l'affectation du contrôleur au site, veuillez réessayer",
    timeout:
      "Votre session a expiré en raison d'une inactivité, veuillez réessayer",
  },
  ping_successful: 'Ping du contrôleur réussi',
  ping_unsuccessful: 'Échec du ping du contrôleur',
  to_continue: 'Pour continuer',
  ping_successful_msg:
    'Cliquez sur le lien suivant pour obtenir le code de vérification',
  verification_code: 'Remplissez le code de vérification ici',
  back: 'Dos',
  assign: 'Attribuer',
  ping_verify:
    'Le ping vers {{serial_number}} a réussi, le contrôleur est actif',
  ping_verify_fail:
    'Aucune réponse reçue du contrôleur {{serial_number}}, le contrôleur est hors ligne',
  tamperedTitle: '(Alerte falsifiée / falsifiée)',
  buttonRestore: 'Restaurer',
  tampered: 'Trafiqué',
  alarmed: 'Alarmé',
  tamperOptions: 'Options de sabotage',
  tamperedAlarm: 'Alarme de sabotage',
  tamperedNormal: 'Normal',
  tamperedEnableCheck: 'Activer',
  tamperedAlarmCheck: 'Alarme',
  tamperedRestoreSuccess: "Restauration de l'alarme du contrôleur réussie",
  readers: 'Lecteurs',
  tamperUpdateSuccess: 'Options de sabotage mises à jour avec succès',
  readersTab: {
    readerPort: 'Port de Lecteurs',
    EditReaderModal: {
      editReader: 'Modifier le lecteur',
      displayName: 'Afficher un nom',
      productCode: 'Code produit',
      port: 'Port',
      serialNumber: 'Numéro de série',
      firmware: 'Micrologiciel',
    },
  },
  sending: 'Envoi en cours',
  receiving: 'Recevoir',
  select_controller_replace: 'Sélectionnez un contrôleur à remplacer',
  process_irreversible:
    'Ce processus est irréversible <br/> Vous pouvez fermer cette fenêtre et revenir à tout moment.',
  press_done_msg:
    'Remplacement terminé, appuyez sur <strong>Terminé</strong> pour fermer la fenêtre',
  confirm_wizard_closed: "Etes-vous sûr de vouloir fermer l'Assistant de Site?",
  confirmation_door_msg:
    'Êtes-vous sûr de vouloir {{dooorAction}} la {{doorStatus}} porte?',
  confirmation_reboot_msg:
    'Êtes-vous sûr de vouloir redémarrer le contrôleur {{controller}}?',
  replace_action: {
    validations: 'Validations générales',
    gencreds: "Génération d'identifiants",
    testcreds: 'Tester les identifiants',
    connected: 'Contrôleur connecté',
    ismain: 'Le contrôleur est le contrôleur principal de ce site',
    modulerestartbegin: 'Le contrôleur redémarre',
    modulerestartend: 'Le contrôleur est en ligne',
    getrectableinfo: "Collecte d'informations sur les entités",
    getdoor: "Collecte d'informations sur les portes",
    getdoorlock: "Collecte d'informations sur les serrures de porte",
    getinputlogic: "Collecte des informations logiques d'entrée",
    getoutput: 'Collecte des informations de sortie',
    getreader: 'Recueillir des informations sur les lecteurs',
    getmodtable: "Recueil d'informations sur les modules",
    getipconn: 'Récupérer la configuration IP',
    setrtc: 'Réglage de la configuration du fuseau horaire',
    setsubcontroller: 'Réglage des sous-contrôleurs',
    setglobalsite: 'Configuration du site global',
    setdesfireapp: "Configuration du maître d'ID d'application Desfire",
    setreadermode: 'Mettre les lecteurs en mode autoprogrammation',
    setholiday: 'Définir les jours fériés',
    setschedule: 'Définir les horaires',
    setaccesslevel: "Définir les niveaux d'accès",
    setdoor: 'Mettre les portes',
    setfactorydefault:
      "Définir les paramètres d'usine par défaut sur l'ancien contrôleur",
    removecontroller: "Supprimer l'ancien contrôleur du site",
    finished: 'Remplacement terminé',
  },
  view_syncing: 'Afficher le processus de synchronisation',
  syncing: 'Synchronisation',
  synced: 'synchronisé',
  enabling: 'Activation',
  enabled: 'activé',
  sync: {
    ctrl: ' Configuration de configuration du contrôleur rassemblée ',
    door: 'Portes rassemblées',
    output: 'Résultats rassemblés',
    reader: 'Les lecteurs rassemblés',
    input: "Logique d'entrée rassemblée",
    doorlock: ' Les serrures de porte rassemblées',
    holiday: ' Des vacances rassemblées ',
    schedule: ' Horaires rassemblés ',
    al: " Niveaux d'accès rassemblés ",
    tamper: ' Tampers Cfg rassemblés ',
    timezone: 'Définir le fuseau horaire',
    global_site: 'Définir le site global',
    krypto: 'Sécurité Krypto activée',
    set_reader: ' Mise en mode autoprogrammation des lecteurs ',
    lockdown: 'Définition du verrouillage',
    set_holiday: 'Définir les jours fériés',
    set_schedule: ' Fixer des horaires ',
    set_al: "Définir les niveaux d'accès",
    set_user: 'Définir les utilisateurs',
    set_card: "Définir les informations d'identification",
    synced: 'Synchronisé',
  },
  synced_msg: {
    reader_synced: 'Lecteurs',
    io_synced: 'Entrées/Sorties',
    al_synced: "Niveau d'accès",
    tz_synced: 'Fuseau horaire',
    krypto: 'Sécurité des cryptomonnaies',
    user_card_synced: 'Utilisateurs et identifiants',
  },
  atrium_unavailable_msg:
    "Aucun contrôleur n'est connecté pour le moment, essayez lorsqu'au moins un de vos contrôleurs est en ligne.",
  door_activation: 'Activation de la porte',
};

export default general;
