const pages = {
  access_levels: {
    create: 'Create an access level',
    edit: 'Edit an access level',
  },
  credentials: {
    create: 'Create a credential',
    edit: 'Edit a credential',
  },
  users: {
    create: 'Create a user',
    edit: 'Edit a user',
  },
};

export default pages;
