export type AnyObject = { [key: string]: any };

/**
 * The function recursively searches through an array of objects,
 * removing any object that contains a specified key-value pair
 * while preserving the structure of the parent objects.
 */
export const removeObjectWithKeyValue = (
  arr: AnyObject[],
  key: string,
  value: any,
) => {
  return arr.filter((obj) => {
    // If the object contains the key with the specified value, we remove it
    if (obj[key] === value) {
      return false;
    }

    // Recursively process if the object has nested properties
    Object.keys(obj).forEach((prop) => {
      if (Array.isArray(obj[prop])) {
        // Apply the same function on nested arrays
        obj[prop] = removeObjectWithKeyValue(obj[prop], key, value);
      } else if (typeof obj[prop] === 'object' && obj[prop] !== null) {
        // If the property is an object, continue the recursive search
        obj[prop] = removeObjectWithKeyValue([obj[prop]], key, value)[0];
      }
    });

    // Keep the object if no match is found
    return true;
  });
};
