const support_tool = {
  // New session
  new_session: 'Nouvelle session de support',
  select_user_type: "Sélectionnez le type d'utilisateur pour donner du support",
  sent_code_to:
    'Pour continuer, complétez cette étape de vérification. Nous avons envoyé un code à',
  please_enter: ". Veuillez l'entrer ci-dessous.",
  account_success: 'Session créée avec succès pour le compte {{account}}',
  dealer_success:
    "Session créée avec succès pour l'utilisateur distributeur {{dealer}}",
  otp_verification: "Vérification de l'OTP",
  new_account_session_confirmation:
    'Êtes-vous sûr de vouloir créer une nouvelle session pour le compte {{account}}?',
  new_dealer_session_confirmation:
    "Êtes-vous sûr de vouloir créer une nouvelle session pour l'utilisateur distributeur {{dealer}}?",

  // Open sessions
  open_sessions: 'Sessions ouvertes',
  search_sessions: 'Rechercher des sessions',
  session_type: 'Type de session',
  support_email: 'E-mail de support',
  end_session: 'Terminer la session',
  end_session_confirmation: 'Êtes-vous sûr de vouloir mettre fin à la session?',
  session_ended: 'Session terminée avec succès',
  session_code: 'Code de session',
  session_code_msg:
    "Vous n'êtes pas le propriétaire de cette session. Pour l'ouvrir, demandez au propriétaire le code de la session et entrez-le ci-dessous.",
  session_opened: 'Session ouverte avec succès',

  // Session window
  select_account: 'Sélectionnez un compte',
  support_session: 'Session de support',
  switch_account: 'Changer de compte',
  session_details: 'Détails de la session',
  support_case: 'Cas de support',
  support_agent: 'Agent de support',
  contact_name: 'Nom du contact',
  contact_number: 'Numéro de contact',
  controller: 'Contrôleur',
  sdk_entities: 'Entités SDK',
  unsynced: 'Non synchronisé',
  tasks: 'Tâches',
  no_session_msg:
    "Il n'y a actuellement aucune session ouverte. Veuillez ouvrir une session pour continuer",

  // Diagnose Controller
  get_information: 'Obtenir des informations',
  diagnose_controller: 'Diagnostiquer - Contrôleur',
  serial_number: 'Numéro de Série',
  last_alive: 'Dernier en vie',
  status_ping: 'Statut Ping',
  restore_global_certificate: 'Restaurer le certificat global',
  create_aws_thing: 'Créer Chose AWS',
  open_support_case: 'Ouvrir un cas de support',
  server_status: 'Statut du serveur',
  frontend: 'Frontend',
  backend: 'Backend',
  database: 'Base de Données',
  iot_services: 'Services IOT',
  controller_details: 'Détails du Contrôleur',
  controller_status: 'État du Contrôleur',

  // User types
  dealer: 'Distributeur',
  account: 'Compte',

  // Controller Tab
  site_name: 'Nom du site',
  status: 'Statut',
  sync_status: 'Statut de synchronisation',
  firmware_version: 'Version du micrologiciel',
  online: 'En ligne',
  offline: 'Hors ligne',
  synced: 'Synchronisé',
  unknown: 'Inconnu',
  sync: 'Synchroniser',
  firmware: 'Micrologiciel',
  sdk: 'SDK',
  syncing: 'Synchronisation...',
  checking: 'Vérification...',
  sync_confirmation:
    'Êtes-vous sûr de vouloir synchroniser le contrôleur {{controller}}? Cette action peut prendre quelques minutes.',
  sync_successfull: 'Contrôleur synchronisé avec succès',

  // SDK Controller
  module_serial: 'Numéro de série du module',
  ping: 'Ping',
  edit_cloud: 'Modifier le cloud',
  object_type: "Type d'objet",
  sub_module: 'Sous-module',
  command: 'Commande',
  record: 'Enregistrer',
  options: 'Options',
  command_tasks: 'Tâches de commande',
  command_task_details: 'Détails de la tâche de commande',
  entities_table: 'Table des entités',
  sending_command: 'Envoi de la commande...',
  open_cloud: 'Ouvrir le cloud',
  description: 'Description',
  tag: 'Tag',
  sync_cloud: 'Synchroniser le cloud',
  sync_controller: 'Synchroniser le contrôleur',
  in_memory: 'En mémoire',
  id: 'ID',
  id_from: 'ID De',
  id_to: 'ID À',
  max: 'Max',
  no_command_task_selected: 'Aucune tâche de commande sélectionnée',
  response: 'Réponse',
  request: 'Requête',
  created_at: 'Créé à',
  created: 'Créé',
  started_at: 'Démarré à',
  started: 'Démarré',
  pending: 'En attente',
  finished: 'Terminé',
  type: 'Type',
  date: 'Date',
  entity: 'Entité',
  tag_name: 'Nom du tag',
  cloud_data: 'Données du cloud',
  controller_data: 'Données du contrôleur',

  // SDK Entities
  search_by_id: 'Rechercher par ID',
  search_by_name: 'Rechercher par nom de champ',
  show_details: 'Afficher les détails',
  per_record: 'Par enregistrement',
  per_controller: 'Par contrôleur',
  search: 'Rechercher',
  from_id: "De l'ID",
  to_id: "À l'ID",
  field_name: 'Nom du champ',
  value: 'Valeur',
  label: 'Étiquette',
  object_status: "Statut de l'objet",
  view: 'Voir',
  push: 'Pousser',
  view_sdk: 'Voir le SDK',
  save_push: 'Enregistrer et pousser',
  entity_id: "ID d'entité",
  entity_sync: 'Entité Sync',
  entity_data: "Données de l'entité",
  records: 'Enregistrements',
  copy: 'Copier',
  paste: 'Coller',
  view_entity: "Voir l'entité",
  push_successfull: "Données de l'entité envoyées avec succès",
  pasted_data: 'Données collées',
  paste_confirmation: 'Voulez-vous coller les données?',
  paste_error: 'Erreur de collage',
  copy_success: 'Données copiées avec succès',
  copy_error: 'Erreur de copie',

  // Unsynced Tab
  name: 'Nom',
  read: 'Lire',
  site: 'Site',
  sync_count_cloud: 'Synchroniser Comptage Cloud',
  sync_count_controller: 'Synchroniser Comptage Contrôleur',
  sync_count: 'Synchroniser Comptage',
};

export default support_tool;
