import { Box } from '@mui/material';

import lockdownImg from 'assets/images/dashboard_icons/lockdown.png';

export const LockdownBlink = () => {
  return (
    <Box
      className="blinkcard-yellow"
      padding={1}
    >
      <img
        src={lockdownImg}
        width={32}
        alt="Lockdown"
      />
    </Box>
  );
};
