import { useEffect } from 'react';

import * as Sentry from '@sentry/react';

import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

const dsn =
  'https://7a7d3922d0b695d983f1a7d65c732f61@o4508087709335552.ingest.us.sentry.io/4508093303357440';

Sentry.init({
  dsn,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],

  tracesSampleRate: process.env.NODE_ENV === 'development' ? 1.0 : 0.2,

  tracePropagationTargets: [
    'localhost',
    /^\//,
    /^https:\/\/cdev\.cdvicloud\.com\/app/,
  ],

  environment: process.env.NODE_ENV,

  enabled: process.env.NODE_ENV === 'production',
});
