import { useSelector } from '../store';

import { OpenSession } from '../interfaces/supportTool.interface';

export const useSession = () => {
  const session: OpenSession = useSelector(
    (state) => state.supportSession.session,
  );

  return session;
};
