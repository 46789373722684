const inputs = {
  inputs: 'Entradas',
  table: {
    hardware: 'Hardware',
    displayName: 'Nombre',
    serialNumber: 'Serial',
    connectionMethod: 'Metodo de conexion',
    normallyOpen: 'Abierto normalmente',
    responseTime: 'Tiempo de respuesta',
    restoreTime: 'Timepo de restauracion',
    searchPlaceHolder: 'Buscar por hardware, nombre o serial',
    enabled: 'Habilitado',
    status: 'Estatus',
    state: 'Estado',
  },
  modal: {
    title: 'Propiedades',
    tabInformation: 'Informacion General',
    TabEvents: 'Eventos',
  },
  form: {
    options: 'Opciones',
    checkEnable: 'Activar',
    radioNormallyOpen: 'Normalmente abierto',
    radioNormallyClose: 'Normalmente cerrado',
    inputResponseTime: 'Tiempo de respuesta (ms)',
    inputRestoreTime: 'Tiempo de restauracion (ms)',
    checkEnableLockdown: 'Enable Lockdown',
    whenOpen: 'Al abrir',
    whenClose: 'Al cerrar',
    startLockdown: 'Al empezar bloqueo',
    stopLockdown: 'Al terminar bloqueo',
  },
  legend: {
    normal: 'Normal',
    trouble: 'Problema',
    active: 'Activo',
    unknown: 'Desconocido',
  },
};

export default inputs;
