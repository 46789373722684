import * as actions from '../actions/hardware-outputs';
import { AnyAction } from '@reduxjs/toolkit';

interface State {
  outputs: any[];
}

const initialState: State = {
  outputs: [],
};

const hardwareOutputsReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case actions.SET_OUTPUTS:
      return {
        ...state,
        outputs: [...action.payload],
      };

    case actions.UPDATE_OUTPUT: {
      const updatedOutputs = state.outputs.map((output) => {
        return output.output === action.payload.output
          ? action.payload
          : output;
      });

      return {
        ...state,
        outputs: updatedOutputs,
      };
    }

    default:
      return state;
  }
};

export default hardwareOutputsReducer;
