import { useEffect, useState } from 'react';

import { Routes, Route, useLocation } from 'react-router-dom';

import { useDispatch, useSelector } from '../store';
import { removeUser, setUser } from '../store/slices/user';

import SplashScreen from '../components/SplashScreen/SplashScreen';

import { PrivateRoutes } from './PrivateRoutes';
import { PublicRoutes } from './PublicRoutes';

import { useSession } from '../hooks/useSession';

const AppRoutes = () => {
  const location = useLocation();

  const session = useSession();
  const user = useSelector((store) => store.user.user);

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);

  // Temporary to force logout of users with previous state shape
  const userActionMap = useSelector((store) => store.user.actionMap);
  const isOldActionShape = (obj: any) => {
    return (
      typeof obj === 'object' &&
      obj !== null &&
      Object.values(obj).every((value) => typeof value === 'boolean')
    );
  };

  useEffect(() => {
    if (
      location.pathname.includes('activate_account') ||
      (user &&
        (!user.permissions?.[0].permissions || isOldActionShape(userActionMap))) // Force logout of users with previous state shape
    ) {
      dispatch(removeUser());
    }

    if (!session && user?.is_support) {
      dispatch(setUser({ ...user, selectedOrg: null, selectedDealer: null }));
    }

    setLoading(false);
  }, []);

  if (loading) {
    return <SplashScreen />;
  }

  return (
    <Routes>
      {user ? (
        <Route
          path="/*"
          element={<PrivateRoutes />}
        />
      ) : (
        <Route
          path="/*"
          element={<PublicRoutes />}
        />
      )}

      {/* Health check */}
      <Route
        path="/status"
        element={<div>Status: OK</div>}
      />
    </Routes>
  );
};

export default AppRoutes;
