const doors = {
  doors: 'Puertas',
  search_door: 'Buscar puerta',
  search_event: 'Buscar evento',
  configuration: 'Configuración',
  events: 'Eventos',
  notes: 'Notas',
  basic: 'Básico',
  advanced: 'Avanzado',
  side_b: 'Lado de salida',
  side_a: 'Lado de entrada',
  options: 'Opciones',
  schedule: 'Horario',
  timing: 'Temporización',
  user_location: 'Ubicación del usuario',
  update_user_location: 'Actualizar ubicación del usuario en',
  timing_user_location: 'Tiempo de ubicación del usuario activado',
  edit: 'Editar',
  edit_basic: 'Editar-Básico',
  edit_advanced: 'Editar-Avanzado',
  door_commands: 'Comandos de puerta',
  legend_title: 'Leyenda',
  restore_tamper_alarm: 'Restaurar alarma de manipulación',
  no_tampered_msg: 'La puerta no está manipulada.',
  lockdown_grant: 'Conceder acceso de bloqueo',
  no_lockdown_msg: 'La puerta no está bloqueada.',
  total_active_doors: 'Total de puertas activas',
  form: {
    serial: 'Serial',
    door: 'Puerta',
    active: 'Activo',
    state: 'Estado',
    lock_status: 'Estado de bloqueo',
    access_status: 'Estado de acceso',
    grant_access: 'Conceder acceso',
    disable_access: 'Deshabilitar acceso',
    type_here: 'Escriba aquí',
    name: 'Nombre',
    controller_name: 'Nombre del controlador',
    unlock_schedule: 'Horario de desbloqueo',
    unlock_first: 'Desbloquear en el primer acceso/primer hombre en entrar',
    relock_door_open: 'Rebloquear con puerta abierta',
    relock_door_closed: 'Rebloquear con puerta cerrada',
    left_open: 'Se puede dejar abierta',
    double_swipe: 'Doble deslizamiento',
    access_granted: 'Acceso permitido',
    doors_open: 'Puertas abiertas',
    unlock_time: 'Tiempo de desbloqueo (segundos)',
    extended_unlock: 'Desbloqueo extendido (segundos)',
    open_prealarm: 'Prealarma de apertura demasiado larga (segundos)',
    open_alarm: 'Alarma de apertura demasiado larga (segundos)',
    rex: 'REX',
    reader: 'Lector',
    contact_input: 'Entrada de contacto',
    lock: 'Cerrar',
    cards: 'Credenciales',
    pin: 'y PIN',
    keypad_code: 'Código del teclado',
    door_open: 'Y aunque la puerta esté abierta',
    interlock_side: 'Interbloqueo en este lado',
    two_man_rule: 'Regla de dos hombres (chape)',
    pin_schedule: 'Horario de PIN',
    rex_schedule: 'Horario de REX',
    unlock_options: 'Opciones de desbloqueo',
    card_only: 'Solo tarjeta',
    keypad_only: 'Solo teclado',
    card_or_keypad: 'Tarjeta o teclado',
    card_and_keypad: 'Tarjeta y teclado',
    none: 'Ninguno',
  },
  table: {
    serial: 'SERIAL',
    door: 'PUERTA',
    active: 'ACTIVO',
    state: 'ESTADO',
    lock_status: 'ESTADO DE BLOQUEO',
    access_status: 'ESTADO DE ACCESO',
    grant_access: 'CONCEDER ACCESO',
    disable_access: 'DESHABILITAR ACCESO',
    date_time: 'FECHA Y HORA',
    description: 'DESCRIPCIÓN',
    object: 'OBJETO',
    instigator: 'INSTIGADOR',
  },
  legend: {
    closed: 'Cerrado',
    pre_alarm: 'Prealarma',
    alarm: 'Alarma',
    locked: 'Bloqueado',
    unlocked: 'Desbloqueado',
    unlocked_schedule: 'Desbloqueado por horario',
    access_disabled: 'Acceso deshabilitado',
    trouble: 'Problema',
    open: 'Abierto',
    alarm_forced: 'Alarma / Forzada',
    locked_operator: 'Bloquear (Sobrescribir)',
    unlocked_operator: 'Desbloquear (Sobrescribir)',
    access_enabled: 'Acceso habilitado',
    unknown: 'Desconocido',
    door_tampered: 'En alarma de manipulación',
    offline: 'Desconectado',
    tampered_lockdown: 'Manipulado / Bloqueado',
  },
};

export default doors;
