const zod = {
  date: 'Esta fecha no es válida',
  email: 'Este correo electrónico no es válido',
  expiration_after_activation:
    'La fecha introducida debe ser posterior a la fecha de activación',
  hex: 'El valor hexadecimal no es válido, sólo debe contener los dígitos 0-9 y las letras A-F (mayúsculas o minúsculas)',
  min_length: 'El valor introducido es demasiado corto',
  max_length: 'El valor introducido es demasiado largo',
  max_value: 'El valor máximo es {{ max }}',
  min_value: 'El valor mínimo es {{ min }}',
  required: 'Este campo es obligatorio',
  url: 'Esta URL no es válida',
  value_not_valid: 'Valor no válido',
};

export default zod;
