import { useSelector } from '../store';

export const useUser = () => {
  const user = useSelector((state) => state.user.user);

  if (!user) {
    throw new Error('User must be authenticated');
  }

  return user;
};
