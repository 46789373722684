/*
 * Description: customHook for handling snackAlerts global state
 * Created by: Ricardo Muchacho
 * Created at: 08/28/2024
 */

import { useDispatch } from '../store';
import { closeSnackAlert, setSnackAlert } from '../store/actions/snack-alert';
import { useCallback } from 'react';

const useAlert = () => {
  const dispatch = useDispatch();

  const successAlert = useCallback(
    (msg: string) => {
      dispatch(
        setSnackAlert({ open: true, message: msg, severity: 'success' }),
      );
    },
    [dispatch],
  );

  const infoAlert = useCallback(
    (msg: string) => {
      dispatch(setSnackAlert({ open: true, message: msg, severity: 'info' }));
    },
    [dispatch],
  );

  const errorAlert = useCallback(
    (msg: string) => {
      dispatch(setSnackAlert({ open: true, message: msg, severity: 'error' }));
    },
    [dispatch],
  );

  const closeAlert = useCallback(() => {
    dispatch(closeSnackAlert());
  }, [dispatch]);

  return { successAlert, infoAlert, errorAlert, closeAlert };
};

export default useAlert;
