import { useSelector } from 'store';
import { hasLockdownStarted } from 'helpers/hasLockdownStarted';
import { useSelectedSite } from './useSelectedSite';

export const useSelectedSiteLockdownStatus = () => {
  const selectedSiteId = useSelectedSite()?.site;
  const lockdowns = useSelector((state) => state.lockdowns.lockdowns);

  const hasStarted = hasLockdownStarted(selectedSiteId, lockdowns);

  return { isLockdownActive: hasStarted };
};
