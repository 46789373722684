const global_settings = {
  keypad_tab: 'Code clavier',
  fields_tab: "Champs personnalisés de l'utilisateur",
  code_length: 'Longueur du code utilisateur',
  min_length: 'Longueur minimale',
  max_length: 'Longueur maximale',
  keypad_confirmation_msg:
    'Êtes-vous sûr de vouloir changer la longueur du code utilisateur de {{initialLength}} à {{keypadCodeLength}}?',
  keypad_var_confirmation_msg:
    'Êtes-vous sûr de vouloir changer la longueur minimale et maximale du code utilisateur ?',
  field_label: 'Étiquette du champ',
  field_type: 'Type de champ',
  fields_confirmation_msg:
    'Êtes-vous sûr de vouloir enregistrer les modifications ? Toute suppression de champs personnalisés affectera les utilisateurs qui les avaient.',
  fields_multi_confirmation_msg:
    'Le passage au multi-site affectera tous les sites, supprimant toutes les données qui pourraient y être, êtes-vous sûr de vouloir continuer ?',
  fields_delete_msg:
    'Êtes-vous sûr de vouloir supprimer ce champ personnalisé ? Il a été attribué à {{user_count}} utilisateurs.',
  fields_success: 'Champs personnalisés enregistrés avec succès',
  fields_error: "Erreur lors de l'enregistrement des champs personnalisés",
  keypad_success: 'Longueur du code utilisateur modifiée avec succès',
  keypad_error: 'Erreur lors du changement de la longueur du code utilisateur',
  keypad_exists:
    'Il y a des utilisateurs avec un code clavier, les modifications seront limitées.',
  add_new_field: 'Ajouter un nouveau champ',
  no_fields_configured: 'Aucun champ personnalisé configuré.',
  all_fields_removed:
    'Tous les champs ont été supprimés. Enregistrez pour appliquer ces changements.',
};

export default global_settings;
