const cards = {
  cards: 'Credenciales',
  search_cards: 'Buscar Credencial/Nombre para mostrar',
  add_card: 'Nueva Credencial',
  update_card: 'Credencial',
  options: 'Opciones',
  general_information: 'Información general',
  assign_user: 'Asignar Usuario',
  assigned_user: 'Usuario asignado',
  assign_msg: '¿Quieres asignar la credencial {{ card }} a {{ user }}?',
  assign_reader:
    '¿Quieres escanear con lector {{ reader }} del sitio {{ site }}?',
  unassign_user_msg:
    '¿Quieres desasignar {{ user }} de la credencial {{ card }}?',
  delete_card_multiple_sites:
    'NOTA: Esta credencial está asociada con más de un sitio, se eliminará de todos ellos.',
  readers: 'Lectores',
  scan_card: 'Escanear credencial',
  search_devices: 'Buscar Dispositivos',
  scan_status: 'Estado de escaneo: ',
  enrolled: 'Inscrita',
  discover: 'Descubrir KPROG',
  use_kprog: 'Usar KPROG',
  kprog: 'KPROG',
  table: {
    card: 'CREDENCIALES',
    format: 'FORMATO',
    name: 'NOMBRE PARA MOSTRAR',
    state: 'ESTADO',
    assigned: 'ASIGNADO',
    user_name: 'NOMBRE',
    reader: 'LECTOR',
    site: 'SITIO',
  },
  form: {
    display_name: 'Nombre para mostrar',
    facility_code: 'Código de instalación',
    card_code: 'Código de tarjeta',
    activation_date: 'Fecha de activacion',
    expiry_date: 'Fecha de caducidad',
    card_format: 'Formato de credencial',
    card_number_hex: 'Número de credencial hexadecimal',
    all: 'Todo',
    assigned: 'Asignado',
    unassigned: 'Sin asignar',
    used_for_access: 'Usado para el acceso',
    stolen: 'Robado',
    lost: 'Perdido',
    status: 'Estado',
    active: 'Activo',
    select_a_reader: 'Seleccione un lector',
    csn: 'C.S.N. (número impreso)',
    registration_code: 'Código de Registro',
  },
  pin_option: {
    not_used: 'No utilizado',
    start_lockdown: 'Iniciar bloqueo',
    stop_lockdLock: 'Detener el bloqueo',
    lockdown_grant: 'El bloqueo otorga acceso',
    area_secured: 'Mantener la puerta segura (mantener el bloqueo)',
  },
};

export default cards;
