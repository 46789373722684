const inputs = {
  inputs: 'Entrées',
  table: {
    hardware: 'Hardware',
    displayName: 'Afficher un nom',
    serialNumber: 'Numéro de série',
    connectionMethod: 'Méthode de connexion',
    normallyOpen: 'Normalement ouvert',
    responseTime: "Temps de réponse d'entrée",
    restoreTime: "Temps de restauration d'entrée",
    searchPlaceHolder:
      "Recherche par matériel, nom d'affichage et numéro de série",
    enabled: 'Activé',
    status: 'Statut',
    state: 'État',
  },
  modal: {
    title: "Propriétés d'entrée",
    tabInformation: 'Informations générales',
    TabEvents: 'Événements',
  },
  form: {
    options: 'Choix',
    checkEnable: 'Activer',
    radioNormallyOpen: 'Normalement ouvert',
    radioNormallyClose: 'Normalement fermé',
    inputResponseTime: "Temps de réponse d'entrée (ms)",
    inputRestoreTime: "Temps de restauration d'entrée (ms)",
    checkEnableLockdowns: 'Activer le confinement',
    whenOpen: 'Quand ouvert',
    whenClose: 'Quand fermé',
    startLockdown: 'Démarrer le confinement',
    stopLockdown: 'Arrêter le confinement',
  },
  lagend: {
    normal: 'Normal',
    trouble: 'Problème',
    active: 'Actif',
    unknown: 'Inconnu',
  },
};
export default inputs;
