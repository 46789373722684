import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AuthPermission,
  IAction,
  IPermission,
} from 'interfaces/permissions.interface';
import user from 'interfaces/user.interface';

interface RoleActionMap {
  [role: string]: Record<string, boolean>;
}

/**
 * Creates a role-based action map from permissions.
 *
 * @param authPermissions - Array of role permissions from auth endpoints
 * @returns Map of roles to their allowed actions
 */
const createActionMap = (authPermissions: AuthPermission[]) => {
  const actionMap: RoleActionMap = {};

  for (const { role, permissions } of authPermissions) {
    actionMap[role] = {};

    for (const perm of permissions) {
      for (let i = 1; i <= 8; i++) {
        const actionKey = `action${i}` as keyof IPermission;
        const action = perm[actionKey] as IAction;

        if (action?.tag) {
          actionMap[role][action.tag.toLowerCase()] = action.active;
        }
      }
    }
  }

  return actionMap;
};

export interface UserReducerState {
  token: string | null;
  user: user | undefined;
  actionMap: RoleActionMap;
}

const initialState: UserReducerState = {
  token: null,
  user: undefined,
  actionMap: {},
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<user>) => {
      const user = action.payload;
      let userToSet = { ...user };

      if (user.user_profiles.length === 1) {
        userToSet = {
          ...user,
          currentProfile: user.user_profiles[0].dealer_org_id,
          profile_type: user.user_profiles[0].profile_type,
          selectedDealer:
            user.user_profiles[0].profile_type === 'dealer'
              ? {
                  dealer: user.user_profiles[0].dealer_org_id,
                  name: user.user_profiles[0].company_org_name,
                  image: user.selectedDealer?.image,
                }
              : null,
        };
      }

      state.user = userToSet;
      state.actionMap = createActionMap(user.permissions);
    },

    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },

    removeUser: () => initialState,

    changeProfile: (
      state,
      action: PayloadAction<
        Partial<
          Pick<
            user,
            | 'permissions'
            | 'role'
            | 'role_name'
            | 'currentProfile'
            | 'profile_type'
            | 'selectedOrg'
            | 'selectedDealer'
          >
        >
      >,
    ) => {
      if (state.user) {
        state.user = {
          ...state.user,
          ...action.payload,
        };
      }
    },

    updateSelectedOrg: (
      state,
      action: PayloadAction<Partial<user['selectedOrg']>>,
    ) => {
      if (!state.user) return;

      if (!action.payload) {
        state.user.selectedOrg = null;
        return;
      }

      if (state.user.profile_type === 'organization') {
        const siteId = action.payload.selectedSite?.site;
        const siteData = state.user.sites?.find((s) => s.site === siteId);

        state.user.role = siteData?.role ?? '';
        state.user.role_name = siteData?.role_name ?? '';
      }

      state.user.selectedOrg = {
        ...(state.user.selectedOrg ?? {}),
        ...action.payload,
      } as user['selectedOrg'];
    },
  },
});

export const {
  setUser,
  setToken,
  removeUser,
  changeProfile,
  updateSelectedOrg,
} = userSlice.actions;

export default userSlice.reducer;
