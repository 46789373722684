import { AnyAction } from '@reduxjs/toolkit';
import { SET_ALERT, RESET_ALERT } from '../actions/snack-alert';
import Alert from 'interfaces/alert.interface';

// Initial state
const initialState = {
  open: false,
  message: '',
  severity: '',
};

// Reducer
const snackAlertReducer = (
  state = initialState as Alert,
  action: AnyAction,
) => {
  switch (action.type) {
    case SET_ALERT:
      const { alert } = action;
      return {
        ...state,
        ...alert,
      };
    case RESET_ALERT:
      return {
        ...state,
        open: false,
      };
    default:
      return state;
  }
};

export default snackAlertReducer;
