import { ReactNode } from 'react';

import * as Sentry from '@sentry/react';

import { Fallback } from './Fallback';

type ErrorBoundaryProps = {
  children: ReactNode;
};

function ErrorBoundary({ children }: ErrorBoundaryProps) {
  return (
    <Sentry.ErrorBoundary
      fallback={({ eventId }) => <Fallback eventId={eventId} />}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
}

export { ErrorBoundary };
