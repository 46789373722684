import { PickersActionBarProps } from '@mui/x-date-pickers-5/PickersActionBar';
import dayjs from 'dayjs';

type DateType = dayjs.ConfigType;

export type DateRange = {
  from: dayjs.Dayjs | null;
  to: dayjs.Dayjs | null;
};

export type DateFilterPeriodId = keyof typeof DATE_FILTER_PERIODS;

export type DateRangeActionBars = {
  from: React.ElementType<PickersActionBarProps>;
  to: React.ElementType<PickersActionBarProps>;
};

/**
 * Parses an ISO string to a Date object and sets the time components (hours, minutes, seconds).
 *
 * @param s - The ISO string to be parsed.
 * @returns A Date object with the time components set.
 */
export const parseISOStringToDate = (s: any) => {
  const hours = s.split('T')[1].split(':')[0];
  const minutes = s.split('T')[1].split(':')[1];
  const seconds = s.split('T')[1].split(':')[2].split('.')[0];

  const date = new Date(Date.parse(s));

  date.setHours(hours);
  date.setMinutes(minutes);
  date.setSeconds(seconds);

  return date;
};

/**
 * Formats a given date into a simple `MM/DD/YYYY` string format.
 */
export const dateSimpleFormat = (date: DateType) => {
  if (!date) return '';
  return dayjs(date).utc().format('MM/DD/YYYY');
};

/**
 * Formats a given date into a specified string format or `MM/DD/YYYY hh:mm:ss a` if not provided.
 */
export const dateFormat = (
  date: DateType,
  options?: {
    format?: string;
    keepLocalTime?: boolean;
  },
) => {
  if (!date) return '';
  return dayjs(date)
    .utc(Boolean(options?.keepLocalTime))
    .format(options?.format || 'MM/DD/YYYY hh:mm:ss a');
};

/**
 * A collection of date filter periods with predefined ranges.
 * Each option includes a `label` and a `getRange` function
 * that returns the date range for the respective option.
 */
export const DATE_FILTER_PERIODS = {
  ALL: {
    label: 'general.date_filter.all',
    getRange: () => ({
      from: null,
      to: null,
    }),
  },
  TODAY: {
    label: 'general.date_filter.today',
    getRange: () => ({
      from: dayjs.utc().startOf('day'),
      to: dayjs.utc().endOf('day'),
    }),
  },
  YESTERDAY: {
    label: 'general.date_filter.yesterday',
    getRange: () => ({
      from: dayjs.utc().subtract(1, 'day').startOf('day'),
      to: dayjs.utc().subtract(1, 'day').endOf('day'),
    }),
  },
  THIS_WEEK: {
    label: 'general.date_filter.this_week',
    getRange: () => ({
      from: dayjs.utc().startOf('week'),
      to: dayjs.utc().endOf('week'),
    }),
  },
  LAST_WEEK: {
    label: 'general.date_filter.last_week',
    getRange: () => ({
      from: dayjs.utc().subtract(1, 'week').startOf('week'),
      to: dayjs.utc().subtract(1, 'week').endOf('week'),
    }),
  },
  THIS_MONTH: {
    label: 'general.date_filter.this_month',
    getRange: () => ({
      from: dayjs.utc().startOf('month'),
      to: dayjs.utc().endOf('month'),
    }),
  },
  LAST_MONTH: {
    label: 'general.date_filter.last_month',
    getRange: () => ({
      from: dayjs.utc().subtract(1, 'month').startOf('month'),
      to: dayjs.utc().subtract(1, 'month').endOf('month'),
    }),
  },
  THIS_YEAR: {
    label: 'general.date_filter.this_year',
    getRange: () => ({
      from: dayjs.utc().startOf('year'),
      to: dayjs.utc().endOf('year'),
    }),
  },
  LAST_7_DAYS: {
    label: 'general.date_filter.last_7_days',
    getRange: () => ({
      from: dayjs.utc().subtract(7, 'day').startOf('day'),
      to: dayjs.utc().endOf('day'),
    }),
  },
  LAST_30_DAYS: {
    label: 'general.date_filter.last_30_days',
    getRange: () => ({
      from: dayjs.utc().subtract(30, 'day').startOf('day'),
      to: dayjs.utc().endOf('day'),
    }),
  },
  LAST_60_DAYS: {
    label: 'general.date_filter.last_60_days',
    getRange: () => ({
      from: dayjs.utc().subtract(60, 'day').startOf('day'),
      to: dayjs.utc().endOf('day'),
    }),
  },
  LAST_90_DAYS: {
    label: 'general.date_filter.last_90_days',
    getRange: () => ({
      from: dayjs.utc().subtract(90, 'day').startOf('day'),
      to: dayjs.utc().endOf('day'),
    }),
  },
  LAST_180_DAYS: {
    label: 'general.date_filter.last_180_days',
    getRange: () => ({
      from: dayjs.utc().subtract(180, 'day').startOf('day'),
      to: dayjs.utc().endOf('day'),
    }),
  },
  CUSTOM: {
    label: 'general.date_filter.custom',
    getRange: () => ({
      from: dayjs.utc().startOf('day'),
      to: dayjs.utc().endOf('day'),
    }),
  },
};
