import axios from 'axios';

import { constants } from './constants';

const axiosInstance = axios.create({
  baseURL: constants.BASE_URL,
  withCredentials: true,
});

export default axiosInstance;
