import { Box, Typography } from '@mui/material';

type FallbackProps = {
  eventId: string;
};

function Fallback({ eventId }: FallbackProps) {
  return (
    <Box
      sx={{
        marginTop: { xs: 20, sm: 24 },
        minHeight: '100vh',
        padding: 2,
        textAlign: 'center',
      }}
    >
      <Typography
        color="error"
        component="h1"
        variant="h4"
      >
        Oops! Something went wrong.
      </Typography>

      <Typography mt={3}>
        The issue has been reported to our development team and will be resolved
        as soon as possible. Thank you for your patience.
      </Typography>

      <Typography
        sx={{
          mt: 4,
          overflowWrap: 'break-word',
        }}
      >
        Event ID: {eventId}
      </Typography>
    </Box>
  );
}

export { Fallback };
