const resources = {
  table: {
    product: 'Product',
    category: 'Category',
    description: 'Description',
    status: 'Status',
    date: 'Date',
    actions: 'Actions',
  },
  form: {
    product: 'Product',
    category: 'Category',
    description: 'Description',
    content: 'Content',
    url: 'URL',
  },
  attachments: 'Attachments',
  files: 'Files',
  video_url: 'Video URL',
  create_new: 'New Resource',
  search_resources: 'Search resources',
  resources: 'Resources',
  add_new_resource: 'New Resource',
  preview_resource: 'Preview Resource',
  update_resource: 'Update Resource',
  drag_drop: 'Drag and drop your file here or',
  upload_a_file: 'Upload a file',
};

export default resources;
