import { ReactNode } from 'react';

import { LocalizationProvider } from '@mui/x-date-pickers-5';
import { AdapterDayjs } from '@mui/x-date-pickers-5/AdapterDayjs';

interface Props {
  children: ReactNode;
}

function PickersProviderV5({ children }: Readonly<Props>) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      {children}
    </LocalizationProvider>
  );
}

export { PickersProviderV5 };
