import React, { useMemo } from 'react';

import { Box, Divider, MenuItem, Stack, Typography } from '@mui/material';

import { useTranslation } from 'react-i18next';

import Avatar from 'components/Avatar/Avatar';
import profilePlaceholderImg from 'assets/images/misc/avatars/profile_placeholder_small.png';
import { idFormat } from 'helpers/idFormat';
import user from 'interfaces/user.interface';
import { SiteLanguage } from 'components/SiteLanguage/SiteLanguage';
import { useSession } from 'hooks/useSession';

interface props {
  user: user;
  handleActionClick: (
    setting: string,
    e: React.MouseEvent<HTMLElement>,
  ) => void;
  setAnchorElLang: (value: React.SetStateAction<null | HTMLElement>) => void;
}

const UserInfoMenuContent = ({
  user,
  handleActionClick,
  setAnchorElLang,
}: props) => {
  const { t } = useTranslation();

  const currentProfile = useMemo(
    () =>
      user.user_profiles.find(
        (profile: any) => profile.dealer_org_role_id === user.role,
      ),
    [user.user_profiles],
  );
  const session = useSession();

  const settings: { config: string; enabled: boolean }[] = [
    { config: 'config.profile', enabled: !session },
    // { config: 'config.dealer_info', enabled: user.profile_type === 'dealer' },
    {
      config: 'config.switch_profile',
      enabled: (user.user_profiles.length >= 2 || false) && !session,
    },
    { config: 'config.change_password', enabled: !session },
    { config: 'config.language', enabled: true },
    { config: 'config.logout', enabled: !session },
  ];

  return (
    <>
      <Stack
        direction="row"
        padding={1}
        alignItems="center"
        spacing={2}
        textAlign={'left'}
      >
        <Avatar
          className="transparent-avatar"
          variant="rounded"
          sx={{ width: 40, height: 40 }}
          src={
            user.logo
              ? `data:image/png;base64,${user.logo}`
              : '/static/images/avatar/2.jpg'
          }
        >
          <img
            src={
              user.logo
                ? `data:image/png;base64,${user.logo}`
                : profilePlaceholderImg
            }
            alt={user.first_name + ' ' + user.last_name + ' avatar'}
          />
        </Avatar>
        <Box>
          <Typography
            component="div"
            variant="subtitle1"
          >
            {user.first_name} {user.last_name}
          </Typography>
          <Typography
            component="div"
            variant="caption"
          >
            {currentProfile?.company_org_name || null}
          </Typography>
        </Box>
      </Stack>

      <Divider />

      {settings.map(
        (s) =>
          s.enabled === true && (
            <MenuItem
              key={s.config}
              style={{ margin: 6 }}
              id={`menu-item-${idFormat(s.config)}`}
              onClick={(e) => {
                if (s.config !== 'config.language') {
                  handleActionClick(s.config, e);
                }
                if (s.config === 'config.language') {
                  setAnchorElLang(e.currentTarget);
                }
              }}
            >
              <Stack
                direction={'row'}
                width="100%"
                justifyContent="space-between"
                gap={1}
              >
                <Typography>{t(s.config)}</Typography>

                {s.config === 'config.language' && <SiteLanguage />}
              </Stack>
            </MenuItem>
          ),
      )}
    </>
  );
};

export default UserInfoMenuContent;
