const dealers = {
  header: 'Dealers List',
  dealers: 'Dealers',
  employee: 'Employee',
  edit_dealers: 'Edit Dealer',
  dealers_details: 'Dealer Details',
  add_dealers: 'New Dealer',
  add_emp: 'New Employee',
  edit_emp: 'Employee',
  search_dealers: 'Search Dealers',
  search_employee: 'Search Employees',
  search_organizations: 'Search Accounts',
  delete_dealers_message: 'Are you sure you want to delete',
  selected_dealerss: 'the selected dealers',
  confirm_modal_cancel: 'No, cancel',
  confirm_modal_confirm: 'Yes, delete',
  create_dealer: 'New Dealer',
  update_dealer: 'Dealer',
  dealer_info: 'Dealer Info',
  review_dealer_account: 'Review Dealer Account',
  all: 'All',
  active: 'Active',
  inactive: 'Inactive',
  submit: 'Submit',
  cancel: 'Cancel',
  update: 'Update',
  independet: 'Independent dealer',
  national: 'National account dealer',
  na: 'Asscoiated NA dealer',
  back_to_list: 'Back to list',
  edit: 'Edit',
  profile: 'Profile',
  organizations: 'Accounts',
  controllers: 'Controllers',
  doors: 'Doors',
  users: 'Users',
  invoices: 'Invoices',
  myplan: 'My Plan',
  dealer_updated: 'Dealer updated',
  dealer_role: 'Dealer Role',
  emp_role: 'Employee Role',
  set_dealer: 'Set Dealer',
  change_dealer_msg: 'Do you want to exit the dealer?',
  select_dealer_msg: 'Please go to Dealers and select one',
  confirm_action: 'Are you sure you want to change the status to',
  manage_plan: 'Manage my plan',
  add_employee: "Add employee's",
  calculate_for_account: 'Calculate for your accounts',
  how_many_doors: 'How many doors',
  for_doors: 'For one or more doors',
  doors_to_add: 'How many doors licenses would you like to add?',
  billing_cycle: 'Billing cycle',
  card_enrollment: 'Credential Enrollment',
  card_information: 'Credential Information',
  access_all_accounts: 'Access to all Accounts',
  remove_access_all: 'Remove Access to All Accounts',
  site_monitor: 'Site monitor',
  how_you_discover: 'How did you discover CDVI Cloud',
  no_alerts_reported: 'No alerts reported',
  remove_access_msg: 'Do you want to remove access to {{name}}?',
  set_access_msg: 'Do you want to set access to {{name}}?',
  grant_all_access: 'Do you want to grant access to all accounts?',
  remove_all_access: 'Do you want to remove access to all accounts?',
  set_permissions: 'Do you want to set up the permissions',
  dealer_registration: 'Dealer Registration',
  assign_site: 'Assign Site',
  employees: 'Employees',
  information: 'Information',
  validate: {
    type_email: 'Please, type your email',
    approved_email: 'Approved email address',
    verification_code: 'Verification',
    validation_label:
      'A one time password has been sent to your email, please check your inbox',
    done: 'Success',
    success: 'Click below to sign in to your CDVI Cloud account',
    continue: 'Continue',
    finish: 'Finish',
    password_must_contain: 'Your password must contain',
    number_of_charachters: '8 to 24 characters',
    lowercase: 'A lowercase letter',
    uppercase: 'An uppercase letter',
    number: 'A number',
    special_character: 'A special character',
    password_match: 'Password match',
  },
  table: {
    company: 'COMPANY',
    full_name: 'FULL NAME',
    email: 'EMAIL',
    country: 'COUNTRY',
    last_login: 'LAST LOGIN',
    active: 'ACTIVE',
  },
  form: {
    type: 'Type of Dealer',
    company: 'Company',
    full_name: 'Full Name',
    first_name: 'First Name',
    last_name: 'Last Name',
    email: 'Email',
    confirm_email: 'Confirmation email',
    country: 'Country',
    address: 'Address',
    city: 'City',
    state: 'State',
    province_territory: 'Province or Territory',
    zip_code: 'Zip Code',
    postal_code: 'Postal Code',
    phone: 'Phone',
    mobile: 'Mobile',
    language: 'Communication Language',
    other: 'Other',
  },
  error: {
    invalid_email: 'Invalid email format',
    invalid_zip: 'Invalid zip code',
  },
  add_edit: {
    add_modal: 'New User',
    edit_modal: 'User',
    success: 'Dealer user added successfully',
    update: 'Dealer user updated successfully',
    error: 'Error adding dealer user',
  },
  preregistration_success:
    'We have sent you a link to complete the registration to your email',
  done: 'Done',
  preregistration: {
    submit: 'Submit',
  },
  user_activation: 'User Activation',
  request: {
    back_to_home: 'Back to home',
    error: 'An error has occurred.',
    msg1: 'We have received your request to become a dealer.',
    msg2: 'We will contact you by email soon!',
    thank_you: 'Thank you, {{ companyName }}!',
  },
  tabs: {
    dealer_info: 'Dealer Info',
    employees: 'Employees',
    payment_methods: 'Payment methods',
  },
};

export default dealers;
