import { useCallback, useEffect, useMemo, useState } from 'react';

import { Outlet } from 'react-router-dom';

import { Box, styled, Theme, Toolbar } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { CSSObject } from '@mui/material/styles';
import { useTheme } from '@mui/system';

import NavigationBar from '../NavigationBar/NavigationBar';
import stratusIcon from 'assets/images/misc/logos/icon_white.svg';
import stratusLogo from 'assets/images/misc/logos/logo_horizontal_white.svg';
import NavigationMenu from '../NavigationMenu/NavigationMenu';
import NavigationMenuMini from '../NavigationMenuMini/NavigationMenuMini';
import NavbarButton from 'components/NavbarButton/NavbarButton';

import { useSelector } from 'store';
import { getUserMenuFromSession } from 'api/api';
import { removeObjectWithKeyValue } from 'helpers/removeObjectWithKeyValue';

import { useIsFloorPlanLiveViewPage } from 'hooks/useIsFloorPlanLiveViewPage';
import { useDrawerOpening } from 'hooks/useDrawerOpening';
import { useSelectedSiteLockdownStatus } from 'hooks/useSelectedSiteLockdownStatus';
import { usePermission } from 'hooks/usePermission';
import { useSelectedSite } from 'hooks/useSelectedSite';
import { useUser } from 'hooks/useUser';

import { AvatarAndSelectionNav } from 'layout/AvatarAndSelectionNav';
import { useSession } from 'hooks/useSession';

const drawerWidth = 270;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 10px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  background: 'transparent',
  height: '64px',
  width: `calc(100% - ${96}px)`,
  marginRight: '8px',
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth + 20}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const accountLevelMenuIds = new Set([
  '07bb22da-6487-11ee-8d30-d70ef3171ee2', // Account info (/organization_info)
  'df071e8a-6788-11ed-b2ae-239e54be31e6', // Sites (/sites/list)
  '7a55ebdc-90f6-11ed-b64b-dbd5cc76cefc', // Users (/users/list)
  '84d0eb18-937e-11ed-b662-7b96c0b27e92', // Cards (/cards/list)
  '562c41e2-095b-11ee-9aad-0f322f2ecac6', // Reports
]);

const sitesIds = new Set([
  'df071e8a-6788-11ed-b2ae-239e54be31e6', // Sites (/sites/list)
  '35b582c6-9069-11ee-a5a9-8f8c8585330e', // Site info (/site/dashboard)
]);

const globalSettingsId = 'c164265a-4f30-585b-a0ba-bb64f2514d57';
const settingsId = '874c34ec-92f1-11ee-a5b3-57a427112e7d';
const accountId = '60d72a50-6788-11ed-b2ae-7bb4c153a654';
const dealerId = 'a6e64690-1830-11ed-98fc-57de738641b7';
const homeId = '478943f2-92bd-11ed-b65d-3fd2824699e7';
const cdviSupportId = 'a5714635-597c-58f1-a280-3b0b045f4d80';
const currentSessionId = 'a4a72c86-0323-50ed-a57f-9c1313404da8';
const interlockListId = '048abc4b-4116-56a2-acbe-bc6e5e28f62f';

interface MenuRoute {
  id: string;
  name: string;
  url: string;
  icon: string;
  children: MenuRoute[];
}

const PrivateLayout = () => {
  const user = useUser();
  const { summary } = useSelector((store) => store.summary);
  const selectedSite = useSelectedSite();

  const theme = useTheme();
  const session = useSession();

  const isFloorPlanLiveViewPage = useIsFloorPlanLiveViewPage();

  const { open, toggle } = useDrawerOpening();
  const [menuRoutes, setMenuRoutes] = useState<MenuRoute[]>([]);
  const matches = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
  const { isLockdownActive } = useSelectedSiteLockdownStatus();
  const hasGlobalSettingsAtAccountLevel = usePermission('globalsettings-save');

  useEffect(() => {
    getMenus();
  }, [user.role, session]);

  const getMenus = async () => {
    try {
      const { menus } = await getUserMenuFromSession();
      setMenuRoutes(menus);
    } catch (error: any) {
      console.error('Get menu from session error:', error);
    }
  };

  const filterMenu = useCallback(
    (menu: MenuRoute[]) => {
      if (user.profile_type === 'dealer') {
        let filteredMenu = menu.filter(
          (m) =>
            (user.selectedOrg || m.id !== accountId) && // Include Account Info only if the user has an org selected
            (summary?.has_payment_method || m.id !== dealerId), // Include Dealer menu only if the user has a payment method
        );

        if (process.env.REACT_APP_INTERLOCKS === 'false') {
          filteredMenu = removeObjectWithKeyValue(
            filteredMenu,
            'id',
            interlockListId,
          ) as MenuRoute[];
        }

        return filteredMenu;
      }

      // CDVI Support
      if (user.is_support) {
        return menu.flatMap((m) => {
          if (
            (m.id === accountId && !session) ||
            (m.id === homeId && session)
          ) {
            return []; // Exclude the item by returning an empty array
          }

          if (m.id === cdviSupportId) {
            return {
              ...m,
              children: m.children.filter((c) =>
                session ? c.id === currentSessionId : c.id !== currentSessionId,
              ),
            };
          }

          return [m]; // Wrap the item in an array to keep it in the result
        });
      }

      return menu;
    },
    [user, summary, session],
  );

  /**
   * Filters out the routes that should not be seen at
   * account level on multisite accounts.
   */
  const filterAccountLevelRoutes = useCallback(
    (menu: MenuRoute) => {
      const accountLevelChildren = menu.children.filter((c) =>
        accountLevelMenuIds.has(c.id),
      );

      if (hasGlobalSettingsAtAccountLevel) {
        const settings = menu.children.find((c) => c.id === settingsId);

        const globalSettings = settings?.children.find(
          (c) => c.id === globalSettingsId,
        );

        if (globalSettings) {
          accountLevelChildren.push(globalSettings);
        }
      }

      return { ...menu, children: accountLevelChildren };
    },
    [hasGlobalSettingsAtAccountLevel],
  );

  const handleSetMenu = useCallback(() => {
    const newMenu = filterMenu(menuRoutes).map((m) => {
      if (m.id !== accountId) {
        return m;
      }

      if (!user.selectedOrg?.multi_site) {
        return {
          ...m,
          children: m.children.filter((c) => !sitesIds.has(c.id)),
        };
      }

      if (!selectedSite?.site) {
        return filterAccountLevelRoutes(m);
      }

      return { ...m };
    });

    return newMenu;
  }, [menuRoutes, user, selectedSite, filterMenu, filterAccountLevelRoutes]);

  const menu = useMemo(() => handleSetMenu(), [handleSetMenu]);

  const getWidth = () => {
    if (matches) {
      return isFloorPlanLiveViewPage
        ? `calc(100% - 16px)`
        : `calc(100% - 96px)`;
    }

    return '100%';
  };

  return (
    <Box
      sx={{ display: 'flex' }}
      style={{
        minHeight: '100vh',
        backgroundColor: theme.palette.background.default,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right top',
      }}
    >
      {!isFloorPlanLiveViewPage && (
        <NavbarButton
          open={open}
          handleDrawer={toggle}
        />
      )}

      <AppBar
        position="fixed"
        open={open}
        sx={{
          boxShadow: 'none',
          borderBottom: '1px solid #ffffff26',
          background: isLockdownActive ? 'red' : theme.palette.primary.gradient,
          width: getWidth(),
        }}
      >
        <Toolbar>
          <NavigationBar />
        </Toolbar>
      </AppBar>

      {/* Drawer */}
      {(matches || (!matches && open)) && !isFloorPlanLiveViewPage && (
        <Drawer
          sx={{
            '& .MuiPaper-root': {
              'background': theme.palette.primary.gradient,
              'borderRight': 0,
              // Add other custom styles as needed
              '& .selected': {
                'backgroundColor': theme.palette.button.dark,
                'borderRadius': 0,
                'fontSize': 15,
                '& img': {
                  filter:
                    'grayscale(1) invert(85%) brightness(1000%) !important',
                },
                '& .MuiListItemText-root': {
                  '& span': {
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                  },
                },
              },
              '& .MuiButtonBase-root': {
                borderRadius: 0,
              },
            },
          }}
          variant="permanent"
          open={open}
          className="mainDrawer"
        >
          <DrawerHeader sx={{ height: '72px', borderBottom: '1px dashed' }}>
            {!open ? (
              <img
                src={stratusIcon}
                height={34}
                alt="Stratus Icon"
              />
            ) : (
              <img
                src={stratusLogo}
                height={34}
                alt="Stratus Logo"
              />
            )}
          </DrawerHeader>

          {open ? (
            <NavigationMenu routes={menu} />
          ) : (
            <NavigationMenuMini routes={menu} />
          )}
        </Drawer>
      )}

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          mt: 8,
          px: { xs: 0, sm: isFloorPlanLiveViewPage ? 1 : 1.5 },
        }}
      >
        <Box mt={2}>
          <AvatarAndSelectionNav />
        </Box>

        <Box mt={2}>
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};

export default PrivateLayout;
