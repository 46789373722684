const zod = {
  date: 'Cette date est invalide',
  email: 'Ce courriel est invalide',
  expiration_after_activation:
    "La date saisie doit être postérieure à la date d'activation",
  hex: 'La valeur hexadécimale est invalide, elle doit contenir uniquement les chiffres 0-9 et les lettres A-F (majuscules ou minuscules)',
  min_length: 'La valeur saisie est trop courte',
  max_length: 'La valeur saisie est trop longue',
  max_value: 'La valeur maximale est {{ max }}',
  min_value: 'La valeur minimale est {{ min }}',
  required: 'Ce champ est requis',
  url: 'Cette URL est invalide',
  value_not_valid: 'Valeur non valide',
};

export default zod;
