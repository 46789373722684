const general = {
  sites: 'Des sites',
  site: 'Site',
  door: 'Porte',
  add_site: 'Nouveau Site',
  edit_site: 'Site',
  edit_site_access: "Modifier l'accès au site",
  search_site: 'Site de recherche',
  copy_organization: 'Copier le compte',
  delete_site_message: 'Etes-vous sûr que vous voulez supprimer {{site}}?',
  notifications: 'Notifications',
  notification: 'Notification',
  events: 'Evénements',
  site_info: 'Informations sur le site',
  total_sites_assigned: 'Total des sites attribués',
  alerts: 'Alertes',
  add_notification: 'Nouvelle notification',
  send_to: 'Envoyer à',
  delete_contact_message:
    'Etes-vous sûr de vouloir supprimer le contact {{contact}}?',
  invalid_email: "Format d'e-mail invalide",
  select_unselect_all: 'Sélectionner/Désélectionner Tout',
  notification_at_least_one: 'Sélectionnez au moins une notification',
  form: {
    name: 'Nom',
    address: 'Adresse',
    country: 'Pays',
    city: 'Ville',
    state: 'État',
    zip_code: 'Code postal',
    timezone: 'Fuseau horaire',
    contact_to: 'Contacter à',
    type_email: 'Tapez e-mail',
    send_notification: 'Envoyer une notification',
    lockdown: 'Lockdown (démarré, arrêté)',
    alarm_forced: 'Porte en alarme forcée',
    alarm_tampared: 'Porte en alarme sabotée',
    tamper: 'Altérer',
    battery_troubles: 'Problèmes de batterie',
    power_troubles: "Problèmes d'alimentation",
  },
  table: {
    name: 'NOM',
    address: 'ADRESSE',
    country: 'PAYS',
    city: 'VILLE',
    state: 'ETAT',
    zipcode: 'CODE POSTAL',
    timezone: 'FUSEAU HORAIRE',
  },
};

export default general;
