import { lazy, Suspense } from 'react';

import { Navigate, Route, Routes } from 'react-router-dom';

import SplashScreen from '../components/SplashScreen/SplashScreen';

import PublicLayout from '../layout/PublicLayout/PublicLayout';

const ActivateAccount = lazy(
  () => import('views-public/ActivateAccount/ActivateAccount'),
);
const DealerRequest = lazy(
  () => import('views-public/DealerRequest/DealerRequest'),
);
const ForgotPassword = lazy(
  () => import('views-public/ForgotPassword/ForgotPassword'),
);
const Login = lazy(() => import('views-public/Login/Login'));
const Preregistration = lazy(
  () => import('views-public/Preregistration/Preregistration'),
);

const PublicRoutes = () => {
  return (
    <Routes>
      <Route element={<PublicLayout />}>
        <Route
          path="/activate_account"
          element={
            <Suspense fallback={<SplashScreen />}>
              <ActivateAccount />
            </Suspense>
          }
        />
        <Route
          path="/dealer_request"
          element={
            <Suspense fallback={<SplashScreen />}>
              <DealerRequest />
            </Suspense>
          }
        />
        <Route
          path="/forgot_password"
          element={
            <Suspense fallback={<SplashScreen />}>
              <ForgotPassword />
            </Suspense>
          }
        />
        <Route
          path="/login"
          element={
            <Suspense fallback={<SplashScreen />}>
              <Login />
            </Suspense>
          }
        />
        <Route
          path="/preregistration"
          element={
            <Suspense fallback={<SplashScreen />}>
              <Preregistration />
            </Suspense>
          }
        />

        {/* Page Not Found */}
        <Route
          path="*"
          element={<Navigate to="/login" />}
        />
      </Route>
    </Routes>
  );
};

export { PublicRoutes };
