/*
 * Description: Redux slice for managing the controller SDK store
 * Created by: Diego Rincón <drincon@binwus.com>
 * Created at: 2024-07-16
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EntityBasicInfo, TableData } from 'interfaces/entities.interfaces';

export interface EntityData {
  basicInfo: Partial<EntityBasicInfo>;
  controllerData: TableData;
  cloudData: TableData;
  initialControllerData: TableData;
}

interface ObjectTypeData {
  [id: string]: EntityData;
}

interface SdkData {
  [objectType: string]: ObjectTypeData;
}

interface SdkState {
  data: SdkData;
  currentId: string;
}

const initialState: SdkState = {
  data: {},
  currentId: '0',
};

const sdkSlice = createSlice({
  name: 'sdk',
  initialState,
  reducers: {
    /**
     * Sets the data for a specific entity.
     *
     * @param state - The current state of the SDK.
     * @param action - The action payload containing the object type, entity ID, and new data.
     */
    setEntityData: (
      state,
      action: PayloadAction<{
        objectType: string;
        id: string;
        newData: Partial<EntityData>;
      }>,
    ) => {
      const { objectType, id, newData } = action.payload;
      state.data[objectType] = state.data[objectType] || {};

      state.data[objectType][id] = {
        ...state.data[objectType][id],
        ...newData,
        controllerData: {
          ...state.data[objectType][id]?.controllerData,
          ...newData.controllerData,
        },
        cloudData: {
          ...state.data[objectType][id]?.cloudData,
          ...newData.cloudData,
        },
        initialControllerData: {
          ...state.data[objectType][id]?.initialControllerData,
          ...newData.initialControllerData,
        },
      };
    },

    /**
     * Sets the basic information for entities of a specific object type.
     *
     * @param state - The current state of the SDK.
     * @param action - The action payload containing the object type and basic information.
     */
    setBasicInfo: (
      state,
      action: PayloadAction<{
        objectType: string;
        basicInfo: Record<string, EntityBasicInfo>;
      }>,
    ) => {
      const { objectType, basicInfo } = action.payload;
      state.data[objectType] = state.data[objectType] || {};

      Object.entries(basicInfo).forEach(([id, info]) => {
        state.data[objectType][id] = {
          ...state.data[objectType][id],
          basicInfo: info,
        };
      });
    },

    /**
     * Sets the current ID in the state.
     *
     * @param state - The current state of the SDK.
     * @param action - The action payload containing the new current ID.
     */
    setCurrentId: (state, action: PayloadAction<string>) => {
      state.currentId = action.payload;
    },

    /**
     * Resets the state to its initial values.
     *
     * @param state - The current state of the SDK.
     */
    resetState: (state) => {
      state.data = {};
      state.currentId = '0';
    },
  },
});

export const { setEntityData, setBasicInfo, setCurrentId, resetState } =
  sdkSlice.actions;

export default sdkSlice.reducer;
