import { useEffect } from 'react';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';

import AppRoutes from './routes/AppRoutes';
import theme from './themes';
import NavigationScroll from './layout/NavigationScroll';
import { postOauthToken } from './api/api';

import { PickersProviderV5 } from 'providers/PickersProviderV5';

import Snack from './components/Snack/Snack';
import './configDayjs';

import { ErrorBoundary } from './sentry/ErrorBoundary';
import { HelpLinksProvider } from 'contexts/HelpLinksContext';

function App() {
  const customTheme = theme();

  const getOauthToken = async () => {
    try {
      await postOauthToken();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getOauthToken();
  }, []);

  return (
    <ErrorBoundary>
      <StyledEngineProvider injectFirst>
        <PickersProviderV5>
          <ThemeProvider theme={customTheme.light}>
            <CssBaseline />
            <NavigationScroll>
              <HelpLinksProvider>
                <AppRoutes />
              </HelpLinksProvider>

              {/* Alerts */}
              <Snack />
            </NavigationScroll>
          </ThemeProvider>
        </PickersProviderV5>
      </StyledEngineProvider>
    </ErrorBoundary>
  );
}

export default App;
