const menu = {
  header: 'Liste des menus',
  add_menu: 'Nouveau Menu',
  search_menu: 'Menu de recherche',
  submit: 'Envoyer',
  cancel: 'Annuler',
  update_menu: 'Menu',
  success_update: 'Mise à jour réussie',
  success_add: 'Nouveau menu ajouté avec succès',
  forma: {
    name: 'Nom',
    description: 'La description',
    path: 'Route',
    order: 'Ordre',
    roles_permission: 'Autorisation des rôles',
    menu_list: 'Liste des menus',
    none: 'Rien',
    icon: 'Icône',
  },
};
export default menu;
