import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SummaryDetails } from 'interfaces/summary.interface';

const initialState: {
  summary: SummaryDetails | undefined;
} = {
  summary: undefined,
};

const summarySlice = createSlice({
  name: 'summary',
  initialState,
  reducers: {
    setStoreSummary: (state, action: PayloadAction<SummaryDetails>) => {
      state.summary = action.payload;
    },
    setHasPaymentMethod: (state, action: PayloadAction<boolean>) => {
      if (state.summary) {
        state.summary.has_payment_method = action.payload;
      }
    },
  },
});

export const { setStoreSummary, setHasPaymentMethod } = summarySlice.actions;

export default summarySlice.reducer;
