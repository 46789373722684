const resources = {
  table: {
    product: 'Producto',
    category: 'Categoría',
    description: 'Descripción',
    status: 'Estado',
    date: 'Fecha',
    actions: 'Acciones',
  },
  form: {
    product: 'Producto',
    category: 'Categoría',
    description: 'Descripción',
    content: 'Contenido',
    url: 'URL',
  },
  attachments: 'Adjuntos',
  files: 'Archivos',
  video_url: 'URL del video',
  create_new: 'Nuevo recurso',
  search_resources: 'Buscar recursos',
  resources: 'Recursos',
  add_new_resource: 'Nuevo recurso',
  preview_resource: 'Vista previa del recurso',
  update_resource: 'Recurso',
  drag_drop: 'Arrastra y suelta tu archivo aquí o',
  upload_a_file: 'Subir un archivo',
};

export default resources;
