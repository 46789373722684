import { Box, CircularProgress } from '@mui/material';

import logo_cdvi from 'assets/images/misc/logos/logo.svg';

const SplashScreen = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        gap: 5,
        height: '100vh',
        flexDirection: 'column',
      }}
    >
      <img
        src={logo_cdvi}
        alt="Stratus Logo"
        width={260}
      />

      <CircularProgress size={75} />
    </Box>
  );
};

export default SplashScreen;
