const navigationMenu = {
  'PERMISSION_OPTION': 'Permissions',
  'MENU_OPTION': 'Menu',
  'USERS': 'Users',
  'ROLES': 'Roles',
  'TESTINGTOOL_OPTION': 'Testing Tool',
  'USER_MANAGEMENT': 'User Management',
  'SECURITY': 'Security',
  'TESTINGTOOLA': 'Controller Testing Tool',
  'Dealer': 'Dealer',
  'DEALER': 'Dealer',
  'Dealer Request': 'Dealer Request',
  'DEALER_OPTION': 'Dealer',
  'Trial': 'Trial',
  'Organization': 'Account',
  'ORGANIZATION_OPTION': 'Accounts',
  'SITE_OPTION': 'Sites',
  'SITE_INFO': 'Site Info',
  'CONTROLLER_OPTION': 'Controller',
  'ORGANIZATION': 'Account',
  'DOOR_OPTION': 'Door',
  'USERORG_OPTION': 'Users',
  'HOLIDAY_OPTION': 'Holiday',
  'SCHEDULE_OPTION': 'Schedule',
  'ACCESSLEVEL_OPTION': 'Access Level',
  'GLOBALSETTINGS_OPTION': 'Global Settings',
  'HOME_OPTION': 'Home',
  'CARD_OPTION': 'Credential',
  'CONTROLLER_ADM': 'Controller',
  'EVENT_OPTION': 'Events',
  'ACCOUNT_INFO': 'Account info',
  'ORG_INFO_PROFILE': 'Account information',
  'TASK_OPTION': 'Tasks',
  'DEALER_INFO': 'Dealer Info',
  'RESOURCES': 'Resources',
  'DEALER_REQUESTS': 'Dealer Requests',
  'LOCKDOWN_OPTION': 'Lockdown',
  'FLOOR_PLAN_OPTION': 'Floor Plan',
  'OPTION_SET_PRICE': 'Pricing',
  'HARDWARE': 'Hardware',
  'SETTINGS': 'Settings',
  'Input': 'Input',
  'Output': 'Output',
  'firmware_manager': 'Firmware Manager',
  'Reports': 'Reports',
  'ORGUSERS_REPORT': 'Users report',
  'ORGCARDS_REPORT': 'Credentials report',
  'Events': 'Events report',
  'CDVI TECH': 'CDVI TECH',
  'CDVI ADMIN': 'CDVI ADMIN',
  'INTERLOCK_OPTION': 'Interlocks',
  'CDVI_SUPPORT': 'CDVI SUPPORT',
  'CDVI_SUPPORT_OPEN_SESSIONS': 'Open Sessions',
  'CDVI_SUPPORT_NEW_SESSION': 'New Session',
  'CDVI_SUPPORT_CURRENT_SESSION': 'Current Session',
  'CDVI_SUPPORT_DIAGNOSE': 'Diagnose Controller',
  'NOTIFICATIONS': 'Notifications',
  'CDVI_HELP_CONFIGURATION': 'Help Configuration',
  'config': {
    profile: 'Profile',
    dealer_info: 'Dealer Info',
    logout: 'Logout',
    change_password: 'Change Password',
    english: 'English',
    spanish: 'Español',
    french: 'Français',
    switch_profile: 'Switch Profile',
    language: 'Language',
  },
  'switch': {
    welcome: 'Welcome, {{name}}',
    please_select: 'Please, select',
    name: 'Name',
    last_login: 'Last Login',
    actions: 'Actions',
    login: 'Login',
    active_profile: 'Active Profile',
    as_dealer: 'As Dealer',
    as_organization: 'As Account',
    as_system: 'As System',
  },
};

export default navigationMenu;
