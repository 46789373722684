import { Link, Typography } from '@mui/material';
import { changeUserSession } from 'api/api';
import { useUser } from 'hooks/useUser';
import { UserProfile } from 'interfaces/user.interface';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import routes from 'routes/routes';
import { useDispatch } from 'store';
import { changeProfile } from 'store/slices/user';

interface Props {
  profile: UserProfile;
  onProfileChange?: () => void;
}

const ProfileSwitcherLink = ({ profile, onProfileChange }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useUser();

  const isProfileActive = (profile: UserProfile) => {
    return user.currentProfile === profile.dealer_org_id;
  };

  /**
   * Switches the user profile, taking into consideration the profile type
   *
   * As opposed to the 'dealer' and 'system' profile types,
   * the 'organization' profile type doesn't have a role, it depends on the sites
   */
  const switchProfile = async (profile: UserProfile) => {
    try {
      const isOrg = profile.profile_type === 'organization';

      /**
       * If the profile is of type organization, it won't have a role
       * so we need to find one from the sites of the organization
       */
      const site = isOrg
        ? user.sites?.find((s) => s.organization === profile.dealer_org_id)
        : null;

      const roleId = (isOrg ? site?.role : profile.dealer_org_role_id) ?? '';
      const roleName =
        (isOrg ? site?.role_name : profile.dealer_org_role_name) ?? '';

      const selectedDealer =
        profile.profile_type === 'dealer'
          ? {
              dealer: profile.dealer_org_id,
              name: profile.company_org_name,
              image: user.selectedDealer?.image,
            }
          : null;

      await changeUserSession(roleId);

      dispatch(
        changeProfile({
          /**
           * For organization profiles, we don't want to preselect a
           * role because it will depend on the site to be selected
           */
          role: isOrg ? '' : roleId,
          role_name: isOrg ? '' : roleName,
          currentProfile: profile.dealer_org_id,
          profile_type: profile.profile_type,
          selectedOrg: null,
          selectedDealer,
        }),
      );

      if (onProfileChange) {
        onProfileChange();
      }

      navigate(routes.home);
    } catch (error: any) {
      console.error('Switch profile error:', error);
    }
  };

  return (
    <>
      {isProfileActive(profile) ? (
        <Typography fontWeight={'bold'}>
          {t('switch.active_profile')}
        </Typography>
      ) : (
        <Link
          href="#"
          onClick={() => switchProfile(profile)}
        >
          {t('switch.login')}
        </Link>
      )}
    </>
  );
};

export default ProfileSwitcherLink;
