const holidays = {
  holidays: 'Días festivos',
  search_holiday: 'Buscar Vacaciones',
  add_holiday: 'Nuevas Vacaciones',
  update_holiday: 'Vacaciones',
  modal_general: 'General',
  modal_events: 'Eventos',
  form: {
    name: 'Nombre',
    occurrence: 'Ocurrencia',
    month: 'Mes',
    day: 'Día',
    occurs: 'Ocurre',
    year: 'Año',
    duration_days: 'Duración (Días',
    hours: 'Horas',
    minutes: 'Minutos',
  },
  table: {
    holiday: 'VACACIÓN',
    state: 'EXPRESAR',
    date: 'FECHA',
    duration: 'DURACIÓN',
    is_yearly: 'ES ANUAL',
    is_relative: 'ES FECHA RELATIVA',
    relative_week_day: 'DÍA DE LA SEMANA RELATIVO',
  },
};

export default holidays;
