import { Box, IconButton } from '@mui/material';

import ClearIcon from '@mui/icons-material/Clear';

import placeholder from 'assets/images/misc/avatars/logo_placeholder.png';

import Avatar from 'components/Avatar/Avatar';

import { useUser } from 'hooks/useUser';
import { useDispatch } from 'store';
import { setUser } from 'store/slices/user';
import { isUserAdmin } from 'helpers/isUserAdmin';

export const AvatarDealer = () => {
  const user = useUser();

  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(setUser({ ...user, selectedOrg: null, selectedDealer: null }));
  };

  return (
    <Box
      sx={{
        'position': 'relative',
        '&:hover .icon-button': {
          opacity: 1,
        },
      }}
    >
      <Avatar
        src={user.selectedDealer?.image ?? placeholder}
        sx={{ width: 40, height: 40 }}
        variant="rounded"
      >
        <img
          src={placeholder}
          alt="Placeholder"
        />
      </Avatar>

      {isUserAdmin(user) && (
        <IconButton
          className="icon-button"
          onClick={handleClick}
          size="small"
          sx={{
            'position': 'absolute',
            'top': '50%',
            'left': '50%',
            'transform': 'translate(-50%, -50%)',
            'backgroundColor': 'background.paper',
            'opacity': 0,
            'transition': 'opacity 0.2s',
            '&:hover': { backgroundColor: 'background.paper' },
          }}
        >
          <ClearIcon
            color="action"
            fontSize="small"
          />
        </IconButton>
      )}
    </Box>
  );
};
