const resources = {
  table: {
    product: 'Produit',
    category: 'Catégorie',
    description: 'Description',
    status: 'Statut',
    date: 'Date',
    actions: 'Actions',
  },
  form: {
    product: 'Produit',
    category: 'Catégorie',
    description: 'Description',
    content: 'Contenu',
    url: 'URL',
  },
  attachments: 'Attachments',
  files: 'Files',
  video_url: 'Video URL',
  create_new: 'Nouvelle ressource',
  search_resources: 'Rechercher des ressources',
  resources: 'Ressources',
  add_new_resource: 'Nouvelle ressource',
  preview_resource: 'Aperçu de la ressource',
  update_resource: 'Ressource',
  drag_drop: 'Glissez-déposez votre fichier ici ou',
  upload_a_file: 'Télécharger un fichier',
};

export default resources;
