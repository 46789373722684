import { Lockdown } from 'interfaces/lockdown.interface';

export const hasLockdownStarted = (
  site: string | undefined,
  lockdowns: Lockdown[],
) => {
  return lockdowns.some(
    (lockdown) => lockdown.site === site && lockdown.lockdown_started,
  );
};
