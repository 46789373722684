import { Breadcrumbs, Link, Typography, useMediaQuery } from '@mui/material';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import { Link as RouterLink, matchPath, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useSelectedSite } from 'hooks/useSelectedSite';
import { useSession } from 'hooks/useSession';
import { useUser } from 'hooks/useUser';

import routes from 'routes/routes';
import { isUserAdmin } from 'helpers/isUserAdmin';

const SelectionNav = () => {
  const { t } = useTranslation();

  const isMd = useMediaQuery('(min-width: 900px)');

  const { pathname, search } = useLocation();

  const selectedSite = useSelectedSite();
  const session = useSession();
  const user = useUser();

  const isMatchingOrganizationList = matchPath(
    routes.organization_list,
    pathname,
  );
  const isMatchingOrganizationCreate = matchPath(
    routes.organization_create,
    pathname,
  );
  const isMatchingOrganizationProfile = matchPath(
    routes.organization_profile,
    pathname,
  );

  const isMatchingDealerInfoView = matchPath(routes.dealer_info_view, pathname);
  const isMatchingFloorPlanView = matchPath(routes.floor_plan_view, pathname);

  const getTo = () => {
    const isMultiSite = user.selectedOrg?.multi_site && selectedSite;

    switch (user.profile_type) {
      case 'organization':
        return isMultiSite ? routes.site_dashboard : routes.home;

      default:
        return isMultiSite ? routes.site_dashboard : routes.organization_info;
    }
  };

  const categoryOptions = [
    {
      routes: [routes.report_users, routes.report_cards, routes.report_events],
      view: 'report',
      label: t('breadcrumbs.reports'),
    },
    {
      routes: [
        routes.holiday_list,
        routes.schedule_list,
        routes.access_level,
        routes.access_level_manager,
        routes.global_settings,
        routes.lockdown,
        routes.floor_plan,
        routes.interlocks_list,

        routes.access_level_create,
        routes.access_level_edit,
      ],
      view: 'setting',
      label: t('breadcrumbs.settings'),
    },
    {
      routes: [
        routes.controller_list,
        routes.input_list,
        routes.output_list,
        routes.firmware_manager,
      ],
      view: 'hardware',
      label: t('breadcrumbs.hardware'),
    },
  ];

  const navigationOptions = [
    { label: t('breadcrumbs.sites'), url: routes.sites_list },
    { label: t('breadcrumbs.doors'), url: routes.door_list },
    { label: t('breadcrumbs.users'), url: routes.report_users },
    { label: t('breadcrumbs.credentials'), url: routes.report_cards },
    { label: t('breadcrumbs.events'), url: routes.report_events },
    { label: t('breadcrumbs.holidays'), url: routes.holiday_list },
    { label: t('breadcrumbs.schedules'), url: routes.schedule_list },
    { label: t('breadcrumbs.globalSettings'), url: routes.global_settings },
    { label: t('breadcrumbs.lockdowns'), url: routes.lockdown },
    { label: t('breadcrumbs.interlocks'), url: routes.interlocks_list },
    { label: t('breadcrumbs.controllers'), url: routes.controller_list },
    { label: t('breadcrumbs.inputs'), url: routes.input_list },
    { label: t('breadcrumbs.outputs'), url: routes.output_list },
    { label: t('breadcrumbs.firmwareManager'), url: routes.firmware_manager },
    { label: t('breadcrumbs.events'), url: routes.event_list },
    { label: t('breadcrumbs.notifications'), url: routes.site_notifications },
    { label: t('breadcrumbs.floorPlans'), url: routes.floor_plan },
    { label: t('breadcrumbs.accessLevels'), url: routes.access_level },
    { label: t('breadcrumbs.credentials'), url: routes.card_list },
    { label: t('breadcrumbs.users'), url: routes.user_org_list },
  ];

  const actionOptions = [
    {
      label: t('breadcrumbs.create'),
      url: routes.organization_create,
    },
    {
      label: t('breadcrumbs.edit'),
      url: routes.organization_profile,
    },

    {
      label: t('breadcrumbs.create'),
      url: routes.access_level_create,
      parent: {
        label: t('breadcrumbs.accessLevels'),
        url: routes.access_level,
      },
    },
    {
      label: t('breadcrumbs.edit'),
      url: routes.access_level_edit,
      parent: {
        label: t('breadcrumbs.accessLevels'),
        url: routes.access_level,
      },
    },

    {
      label: t('breadcrumbs.create'),
      url: routes.credentials_create,
      parent: {
        label: t('breadcrumbs.credentials'),
        url: routes.card_list,
      },
    },
    {
      label: t('breadcrumbs.edit'),
      url: routes.credentials_edit,
      parent: {
        label: t('breadcrumbs.credentials'),
        url: routes.card_list,
      },
    },

    {
      label: t('breadcrumbs.create'),
      url: routes.users_create,
      parent: {
        label: t('breadcrumbs.users'),
        url: routes.user_org_list,
      },
    },
    {
      label: t('breadcrumbs.edit'),
      url: routes.user_org_view,
      parent: {
        label: t('breadcrumbs.users'),
        url: routes.user_org_list,
      },
    },
  ];

  const breadcrumbs = [
    ...(user.selectedDealer && !session
      ? [
          {
            to: isUserAdmin(user) ? '' : routes.home,
            label: user.selectedDealer.name,
            key: 'dealer',
          },
        ]
      : []),
    ...(session
      ? [
          {
            to: routes.support_current_session,
            label: t('supportTool.support_session'),
            key: 'session',
          },
        ]
      : []),
    ...(isMatchingOrganizationList ||
    (user.profile_type !== 'organization' && user.selectedOrg) ||
    isMatchingOrganizationCreate ||
    isMatchingOrganizationProfile
      ? [
          {
            to: routes.organization_list,
            label: t('breadcrumbs.accounts'),
            key: 'organization_list',
          },
        ]
      : []),
    ...(isMatchingDealerInfoView
      ? [
          {
            to: routes.dealer_info_view,
            label: t('breadcrumbs.dealerInfo'),
            key: 'dealer_info_view',
          },
        ]
      : []),
    ...(user.selectedOrg
      ? [
          {
            to: routes.organization_info,
            label: user.selectedOrg.name,
            key: 'org',
          },
        ]
      : []),
    ...(user.selectedOrg?.multi_site && selectedSite
      ? [
          {
            to: routes.site_dashboard,
            label: selectedSite.name,
            key: 'site',
          },
        ]
      : []),
    ...categoryOptions
      .filter(
        ({ routes, view }) =>
          (routes.some((route) => matchPath(route, pathname)) &&
            selectedSite) ||
          search === `?view=${view}`,
      )
      .map(({ view, label }) => ({
        to: `${getTo()}?view=${!selectedSite && view !== 'report' ? '' : view}`,
        label: label,
        key: `category-${view}`,
      })),
    ...navigationOptions
      .filter((option) => matchPath(option.url, pathname))
      .map((option) => ({
        to: option.url,
        label: option.label,
        key: `navigation-${option.label}`,
      })),
    ...actionOptions
      .filter((option) => matchPath(option.url, pathname))
      .flatMap((option) =>
        option.parent
          ? [
              {
                to: option.parent.url,
                label: option.parent.label,
                key: `parent-${option.parent.url}`,
              },
              {
                to: '',
                label: option.label,
                key: `action-${option.label}`,
              },
            ]
          : {
              to: '',
              label: option.label,
              key: `action-${option.label}`,
            },
      ),
  ];

  return (
    <Breadcrumbs
      separator={<NavigateNextIcon fontSize="small" />}
      aria-label="breadcrumb"
      maxItems={isMd ? 8 : 2}
    >
      {breadcrumbs.map(({ to, label, key }, index) => {
        const isLast = index === breadcrumbs.length - 1;

        return isLast || to === '' || isMatchingFloorPlanView ? (
          <Typography
            key={key}
            color="text.primary"
            fontSize={16}
          >
            {label}
          </Typography>
        ) : (
          <Link
            key={key}
            color="inherit"
            fontSize={16}
            component={RouterLink}
            to={to}
            underline="hover"
          >
            {label}
          </Link>
        );
      })}
    </Breadcrumbs>
  );
};

export default SelectionNav;
