const tasks = {
  general: {
    search: 'Buscar por UUID',
    clear: 'Limpiar',
    searchBtn: 'Buscar',
    serialNmbr: 'Número de serie',
    any: 'Cualquiera',
  },
  selectors: {
    selector3: {
      notAvailable: 'No disponible',
      pending: 'Pendiente',
      executing: 'Ejecutando',
      finished: 'Terminado',
    },
    selector4: {
      any: 'Cualquiera',
      today: 'Hoy',
      yesterday: 'Ayer',
      last7Days: 'Últimos 7 días',
      custom: 'Personalizado',
    },
  },
  table: {
    siteName: 'Nombre del sitio',
    date: 'Fecha',
    status: 'Estado',
    id: 'ID',
    id_detail: 'ID Detalle',
    serial_number: 'Número de serie',
    commandtype: 'Tipo de comando',
    entitytype: 'Tipo de entidad',
    success: 'Éxito',
    success_label: 'Éxito',
    started_at: 'Comenzó en',
    finished_at: 'Terminó en',
  },
  modal: {
    title: 'Tarea del controlador-Detalle',
    header: 'Encabezado',
    details: 'Detalles',
    notAvailable: 'No disponible',
    pending: 'Pendiente',
    executing: 'Ejecutando',
    finished: 'Terminado',
  },
};

export default tasks;
