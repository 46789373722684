const interlocks = {
  interlocks: 'Interlocks',
  search: 'Buscar Interlocks',
  add_interlocks: 'Nuevo Interlock',
  label: 'Etiqueta',
  schedule: 'Horario',
  name: 'Nombre',
  edit: 'Editar',
  delete: 'Eliminar',
  add_group: 'Nuevo Grupo de Interlock',
  doors: 'Puertas',
  cancel: 'Cancelar',
  save: 'Guardar',
  add_door: 'Agregar Puertas',
  controller: 'Controlador',
  side: 'Lado',
  group: 'Grupo de Interlock',
  actions: 'Acciones',
  door_name: 'Nombre de puerta',
  search_door_by_name: 'Buscar puerta por nombre',
  side_a: 'Lado A',
  side_b: 'Lado B',
  add: 'Agregar',
  add_selected: 'Agregar seleccionados',
  selected: 'Seleccionado',
  remove: 'Eliminar',
  edit_group: 'Editar grupo de Interlock',
  delete_interlock_confirmation:
    '¿Estás seguro que quieres remover este grupo de Interlocks?',
};

export default interlocks;
