const zod = {
  date: 'This date is invalid',
  email: 'This email is invalid',
  expiration_after_activation:
    'The date entered must be after the activation date',
  hex: 'The hexadecimal value is invalid, it must contain only the digits 0-9 and the letters A-F (upper or lower case)',
  min_length: 'The entered value is too short',
  max_length: 'The entered value is too long',
  max_value: 'The maximum value is {{ max }}',
  min_value: 'The minimum value is {{ min }}',
  required: 'This field is required',
  url: 'This URL is invalid',
  value_not_valid: 'Value not valid',
};

export default zod;
