const breadcrumbs = {
  accessLevels: 'Niveles de acceso',
  accounts: 'Cuentas',
  controllers: 'Controladores',
  credentials: 'Credenciales',
  dealerInfo: 'Información del distribuidor',
  doors: 'Puertas',
  events: 'Eventos',
  firmwareManager: 'Gestor de firmware',
  floorPlans: 'Planos de planta',
  globalSettings: 'Configuraciones globales',
  hardware: 'Hardware',
  holidays: 'Días festivos',
  inputs: 'Entradas',
  interlocks: 'Interbloqueos',
  lockdowns: 'Confinamientos',
  notifications: 'Notificaciones',
  outputs: 'Salidas',
  reports: 'Reportes',
  schedules: 'Horarios',
  settings: 'Configuraciones',
  sites: 'Sitios',
  users: 'Usuarios',

  create: 'Crear',
  edit: 'Editar',
};

export default breadcrumbs;
