import { Box, IconButton, useMediaQuery } from '@mui/material';

import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import { useHelpLinks } from 'contexts/HelpLinksContext';

import { useSelectedSiteLockdownStatus } from 'hooks/useSelectedSiteLockdownStatus';

import { LockdownBlink } from './LockdownBlink/LockdownBlink';

import Clock from './Clock/Clock';
import UserInfoButton from './UserInfoButton/UserInfoButton';

const NavigationBar = () => {
  const isSm = useMediaQuery('(min-width: 600px)');

  const {
    links: { dashboard_help_link },
  } = useHelpLinks();

  const { isLockdownActive } = useSelectedSiteLockdownStatus();

  return (
    <Box
      display="flex"
      alignItems="center"
      gap={1}
      marginLeft="auto"
    >
      {isLockdownActive && <LockdownBlink />}

      {dashboard_help_link && (
        <IconButton
          href={dashboard_help_link}
          target="_blank"
        >
          <HelpOutlineIcon sx={{ fontSize: 28, color: 'white' }} />
        </IconButton>
      )}

      {isSm && <Clock />}

      <UserInfoButton />
    </Box>
  );
};
export default NavigationBar;
