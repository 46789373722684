/**
 * Create URLSearchParams from object
 * @param params - Object with query parameters
 * @param options - Options for the query string
 * @param options.removeFalsy - Remove falsy values from the query string
 * @returns URLSearchParams string
 */
export const createQueryString = (
  params: Record<string, string | null | undefined>,
  options?: { removeFalsy: boolean },
) => {
  const searchParams = new URLSearchParams();
  Object.entries(params).forEach(([key, value]) => {
    if (options?.removeFalsy && !value) return;

    searchParams.append(key, value ?? '');
  });
  return searchParams.toString();
};
