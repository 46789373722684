import { useEffect, useState } from 'react';

import { Box } from '@mui/material';

import { getDealersImg, getOrganizationDetail } from 'api/api';

import { useIsFloorPlanLiveViewPage } from 'hooks/useIsFloorPlanLiveViewPage';
import { useUser } from 'hooks/useUser';

import { useDispatch } from 'store';
import { changeProfile } from 'store/slices/user';

import { AvatarDealer } from './NavigationBar/AvatarDealer/AvatarDealer';
import { AvatarOrganization } from './NavigationBar/AvatarOrganization/AvatarOrganization';

import SelectionNav from './NavigationBar/SelectionNav/SelectionNav';

const AvatarAndSelectionNav = () => {
  const isFloorPlanLiveViewPage = useIsFloorPlanLiveViewPage();
  const user = useUser();

  const dispatch = useDispatch();

  const [organization, setOrganization] = useState<any>();

  const fetchOrganization = async () => {
    try {
      const { data, status } = await getOrganizationDetail(
        user.selectedOrg!.organization,
      );

      if (status === 200) {
        setOrganization(data);
      }
    } catch {}
  };

  useEffect(() => {
    if (user.selectedOrg?.organization) {
      fetchOrganization();
    }
  }, []);

  const fetchImage = async () => {
    try {
      const { image } = await getDealersImg(user.selectedDealer!.dealer);

      if (user.selectedDealer) {
        dispatch(
          changeProfile({
            selectedDealer: {
              ...user.selectedDealer,
              image: image ? `data:image/jpeg;base64,${image}` : '',
            },
          }),
        );
      }
    } catch {}
  };

  useEffect(() => {
    if (user.selectedDealer?.dealer) {
      fetchImage();
    }
  }, [user.selectedDealer?.dealer]);

  return (
    <Box
      display="flex"
      alignItems="center"
      gap={2}
    >
      {isFloorPlanLiveViewPage ? (
        <AvatarOrganization organization={organization} />
      ) : (
        user.selectedDealer && <AvatarDealer />
      )}

      <SelectionNav />
    </Box>
  );
};

export { AvatarAndSelectionNav };
