const pages = {
  access_levels: {
    create: 'Crear un nivel de acceso',
    edit: 'Editar un nivel de acceso',
  },
  credentials: {
    create: 'Crear una credencial',
    edit: 'Editar una credencial',
  },
  users: {
    create: 'Crear un usuario',
    edit: 'Editar un usuario',
  },
};

export default pages;
