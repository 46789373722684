import placeholder from 'assets/images/misc/avatars/logo_placeholder.png';

import Avatar from 'components/Avatar/Avatar';

interface Props {
  organization: any;
}

export const AvatarOrganization = ({ organization }: Props) => {
  return (
    <Avatar
      src={
        organization?.logo
          ? `data:image/png;base64,${organization.logo}`
          : placeholder
      }
      sx={{ width: 40, height: 40 }}
      variant="rounded"
    >
      <img
        src={placeholder}
        alt="Placeholder"
      />
    </Avatar>
  );
};
