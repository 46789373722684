const cards = {
  cards: 'Certificat',
  search_cards: "Rechercher des cartes/Nom d'affichage",
  add_card: 'Nouveau certificat',
  update_card: 'Certificat',
  options: 'Options',
  general_information: 'Informations générales',
  assign_user: 'Attribuer un utilisateur',
  assigned_user: 'Utilisateur désigné',
  assign_msg: 'Voulez-vous assigner la carte {{ card }} à {{ user }} ?',
  assign_reader:
    'Voulez-vous numériser avec le lecteur {{ reader }} du site {{ site }} ?',
  unassign_user_msg:
    'Voulez-vous désassigner {{ user }} de la carte {{ card }} ?',
  delete_card_multiple_sites:
    "REMARQUE: Cette carte est associée à plus d'un site, elle sera supprimée de tous.",
  readers: 'Lectrices',
  scan_card: 'Scanner la carte',
  search_devices: 'Rechercher des appareils',
  scan_status: 'État de la numérisation : ',
  enrolled: 'Inscrite',
  discover: 'Découvrir KPROG',
  use_kprog: 'Utiliser KPROG',
  kprog: 'KPROG',
  table: {
    card: 'CERTIFICAT',
    format: 'FORMAT',
    name: 'AFFICHER UN NOM',
    state: 'ÉTAT',
    assigned: 'ATTRIBUÉ',
    user_name: 'NOM',
    reader: 'LECTEUR',
    site: 'LIEU',
  },
  form: {
    display_name: 'Afficher un nom',
    facility_code: "Code d'installation",
    card_code: 'Code Certificat',
    activation_date: "Date d'activation",
    expiry_date: "Date d'expiration",
    card_format: 'Format Certificat',
    card_number_hex: 'Numéro de carte hexadécimal',
    all: 'Tout',
    assigned: 'Attribué',
    unassigned: 'Non attribué',
    used_for_access: "Utilisé pour l'accès",
    stolen: 'Volé',
    lost: 'Perdu',
    status: 'État',
    active: 'Actif',
    select_a_reader: 'Sélectionnez un lecteur',
    csn: 'C.S.N. (numéro imprimé)',
    registration_code: "Code d'enregistrement",
  },
  pin_option: {
    not_used: 'Non utilisé',
    start_lockdown: 'Démarrer le confinement',
    stop_lockdLock: 'Arrêtez le confinement',
    lockdown_grant: "Le verrouillage accorde l'accès",
    area_secured: 'Garder la porte sécurisée (maintenir le verrouillage)',
  },
};

export default cards;
